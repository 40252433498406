// react
import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// application
import Dropdown from './Dropdown';
// import DropdownCurrency from './DropdownCurrency';
// import DropdownLanguage from './DropdownLanguage';
import { getUserData, logoutUser } from '../../services/storage';
const loggedInUser = getUserData();

function Topbar() {
    const links = [
        {
            title: <FormattedMessage id="topbar.aboutUs" defaultMessage="About Us" />,
            url: '/site/about-us',
        },
        // {
        //     title: <FormattedMessage id="topbar.contactUs" defaultMessage="Contact Us" />,
        //     url: '/site/contact-us'
        // },
        // {
        //     title: <FormattedMessage id="topbar.storeLocation"
        //         defaultMessage="Store Location" />, url: '/site/StoreLocation'
        // },
        // {
        //     title: <FormattedMessage id="topbar.cb tracker" defaultMessage="CB Tracker" />,
        //     url: '/shop/track-order'
        // },
        // {
        //     title: <FormattedMessage id="topbar.blog" defaultMessage="Blog" />,
        //     url: '/blog/category-classic'
        // },
    ];

    const accountLinks = [
        { title: 'Dashboard', url: '/account/dashboard' },
        { title: 'Edit Profile', url: '/account/profile' },
        { title: 'Order History', url: '/account/orders' },
        { title: 'Addresses', url: '/account/addresses' },
        { title: 'Password', url: '/account/password' },
    ];

    const linksList = links.map((item, index) => (
        <div key={index} className="topbar__item topbar__item--link">
            <Link className="topbar-link" to={item.url}>
                {/* {item.title} */}
            </Link>
            <p className="shop-title-text">
                Free delivery with purchase of $100 or more | <span className="space-2">Store</span> hours 6AM-7PM EST/ 7 days
                a week
            </p>

        </div>
    ));

    return (
        <div className="site-header__topbar topbar">
            <div className="topbar__container container">
                <div className="topbar__row">
                    {linksList}
                    <div className="topbar__spring" />
                    <div className="topbar__item d-none">
                        {loggedInUser.isLoggedIn ? (
                            <>
                                <Dropdown
                                    title={<FormattedMessage id="topbar.myAccount" defaultMessage="My Account" />}
                                    items={accountLinks}
                                />
                            </>
                        ) : null}
                    </div>
                    {/* <div className="topbar__item">
                        <DropdownCurrency />
                    </div>
                    <div className="topbar__item">
                        <DropdownLanguage />
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Topbar;
