// react
import React, { useEffect, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import CartIndicator from './IndicatorCart';
import Departments from './Departments';
import Indicator from './Indicator';
import IndicatorAccount from './IndicatorAccount';
import IndicatorSearch from './IndicatorSearch';
import NavLinks from './NavLinks';
import { Heart20Svg, LogoSmallSvg } from '../../svg';
import { API_GET_CART, API_GET_CATALOG, API_GET_PRODUCTS, API_ME } from '../../config/endpoints';
import MenuProducts from '../blocks/MenuProducts';
import { categoryPageUrl, getMediaUrlFromString, getProductDetailsMultiple, parseAmount } from '../../services/helpers';
import { logoutUser, getUserData } from '../../services/storage';
const loggedInUser = getUserData();

function NavPanel(props) {
  const { layout, wishlist, cartData } = props;
  const [categoriesList, setCategoriesList] = useState([{ attributes: { 'catalog.label': '' } }]);
  const [websiteCategoriesList, setWebsiteCategoriesList] = useState([{ attributes: { 'catalog.label': '' } }]);
  const [bestCategoriesList, setBestCategoriesList] = useState([{ attributes: { 'catalog.label': '' } }]);
  const [cart, setCart] = useState({ products: [], included: [], details: { attributes: [] } });
  const [isCartOpen, setCartOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [autocompleteResults, setAutocompleteResults] = useState({ data: [] });
  const [showSearchSuggestions, setShowSearchSuggestions] = useState(false);

  const handleSearchClick = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleCartButton = (e) => {
    e.preventDefault();
    setCartOpen(!isCartOpen);
  };

  let logo = null;
  let departments = null;
  let searchIndicator;

  const handleRemoveCart = (e, item) => {
    e.preventDefault();
    fetch(API_GET_CART + '?id=default&related=product&relatedid=' + item, {
      method: 'DELETE',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('cart', data);
        const cartProducts = data.included.filter((product) => {
          console.log(product);
          return product.type === 'basket.product';
        });

        console.log('cartProducts', cartProducts);
        // setCart(data);
        setCart((prevState) => ({
          ...prevState,
          products: cartProducts,
          details: data.data,
        }));
      });
  };

  useEffect(() => {
    // console.log('from nav', cartData);
    const cartProducts = cartData.included.filter((product) => {
      console.log(product);
      return product.type === 'basket.product';
    });

    setCart((prevState) => ({
      ...prevState,
      products: cartProducts,
      details: cartData.data,
    }));
  }, [cartData]);

  const checkUserAuth = () => {
    fetch(API_ME, {
      method: 'POST',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => {
        if (response.status === 401) {
          // alert('Unauthorized access!');
          console.log();
          if (loggedInUser.isLoggedIn) {
            logoutUser();
          }
        }
        return response.json();
      })
      .then((data) => {
        // console.log('API em', data);
      });
  };
  useEffect(() => {
    checkUserAuth();
  }, []);

  //fetch cart
  useEffect(() => {
    fetch(API_GET_CART + '?id=default', {
      method: 'GET',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log('cart', data);
        const cartProducts = data.included.filter((product) => {
          // console.log(product);
          return product.type === 'basket.product';
        });

        // console.log('cartProducts', cartProducts);
        // setCart(data);
        if (cartProducts.length > 0) {
          setCart((prevState) => ({
            ...prevState,
            products: cartProducts,
            details: data.data,
          }));
        }
      });
  }, [cartData]);

  useEffect(() => {
    fetch(API_GET_CATALOG + '?include=catalog&id=' + process.env.REACT_APP_MAIN_CATEGORY_ID, {
      method: 'GET',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        // console.log(data.included);

        setCategoriesList(data.included);
      })
      .catch((error) => {
        console.log(error);
      });

    fetch(API_GET_CATALOG + '?include=catalog&id=' + process.env.REACT_APP_WEBSITE_CATEGORY_ID, {
      method: 'GET',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        // console.log(data.included);

        setWebsiteCategoriesList(data.included);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetch(API_GET_CATALOG + '?include=catalog&id=' + process.env.REACT_APP_BEST_CATEGORY_ID, {
      method: 'GET',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        setBestCategoriesList(data.included);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let controller = new AbortController();

    const fetchAutocompleteResults = async () => {
      try {
        const response = await fetch(
          `${API_GET_PRODUCTS}?include=catalog,media,price&filter[f_search]=${searchQuery}`,
          {
            signal: controller.signal,
          }
        );

        if (!response.ok) {
          throw new Error('Request failed');
        }

        const data = await response.json();
        console.log('data', data);
        setAutocompleteResults(data);
      } catch (error) {
        console.error('Error fetching autocomplete results:', error);
      }
    };

    if (searchQuery.length > 2) {
      fetchAutocompleteResults();
      setShowSearchSuggestions(true);
    } else {
      setShowSearchSuggestions(false);
      setAutocompleteResults({ data: [] });
    }

    // Cancel previous incomplete request when the query changes
    return () => {
      controller.abort();
      controller = new AbortController();
    };
  }, [searchQuery]);

  const handleInputSearchChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  if (layout === 'compact') {
    logo = (
      <div className="nav-panel__logo">
        <Link to="/">
          <LogoSmallSvg />
        </Link>
      </div>
    );

    searchIndicator = <IndicatorSearch />;
  }

  if (layout === 'default') {
    departments = (
      <div className="nav-panel__departments">
        <Departments />
      </div>
    );
  }

  return (
    <div className="nav-panel">
      <div className="nav-panel__container container">
        <div className="nav-panel__row">
          {logo}
          {departments}

          {/* <div className="nav-panel__nav-links nav-links">
            <NavLinks />
          </div> */}
          <div className="nav-panel__nav-links nav-links">
            <input type="checkbox" id="click" checked={isMobileMenuOpen} />
            <label htmlFor="click" className="mobile-menu-bar" onClick={() => setIsMobileMenuOpen(true)}>
              <i className="fa fa-align-justify"></i>
            </label>
            <ul className="nav-links__list custom-header">
              <li className="nav-links__item close-menu-icon">
                {/* <a href="/site/about-us" className="menu-close-btn">
                  <span>About Us </span>
                </a> */}
                <div className="close-btn" onClick={() => setIsMobileMenuOpen(false)}>
                  <img src="../../../images/megamenu/close.svg" alt="" className="d-block d-lg-none" />
                </div>
              </li>
              <li className="nav-links__item nav-links__item--with-submenu">
                {' '}
                <a href="/shop/categories">
                  {/* <span>Shop by Categories</span> */}
                </a>
              </li>
              <li className="nav-links__item nav-links__item--with-submenu">
                <a>
                  <span>
                    Shop Now
                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" className="nav-links__arrow">
                      <path d="M0.2,0.4c0.4-0.4,1-0.5,1.4-0.1l2.9,3l2.9-3c0.4-0.4,1.1-0.4,1.4,0.1c0.3,0.4,0.3,0.9-0.1,1.3L4.5,6L0.3,1.6C-0.1,1.3-0.1,0.7,0.2,0.4z"></path>
                    </svg>
                  </span>
                </a>

                <div className="nav-links__megamenu nav-links__megamenu--size--nl" style={{ left: 150 }}>
                  <div className="megamenu megamenu--nav-links">
                    <div className="overflow-hidden">
                      <div className="row">
                        <div className="col-12">
                          <ul className="megamenu__links megamenu__links--level--0">
                            {websiteCategoriesList.map((item, key) => (
                              <li
                                key={key}
                                className="megamenu__item megamenu__item--with-submenu megamenu__items__list"
                              >
                                <a href={categoryPageUrl(item)} className="items-megamenu-heading">
                                  {item.attributes['catalog.label']}
                                </a>
                              </li>
                            ))}
                            {categoriesList.map((item, key) => (
                              <li
                                key={key}
                                className="megamenu__item megamenu__item--with-submenu megamenu__items__list"
                              >
                                <a href={categoryPageUrl(item)} className="items-megamenu-heading">
                                  {item.attributes['catalog.label']}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-links__item nav-links__item--with-submenu"></li>
            </ul>
          </div>

          <div className="nav-panel__indicators">
            {/* {searchIndicator} */}
            <div className="indicator indicator--trigger--click undefined indicator--opened">
              <button type="button" className="indicator__button" onClick={handleSearchClick}>
                {!isSearchOpen ? (
                  <span className="indicator__area">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      className={`indicator__icon indicator__icon--open`}
                    >
                      <path d="M19.2,17.8c0,0-0.2,0.5-0.5,0.8c-0.4,0.4-0.9,0.6-0.9,0.6s-0.9,0.7-2.8-1.6c-1.1-1.4-2.2-2.8-3.1-3.9C10.9,14.5,9.5,15,8,15 c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7c0,1.5-0.5,2.9-1.3,4c1.1,0.8,2.5,2,4,3.1C20,16.8,19.2,17.8,19.2,17.8z M8,3C5.2,3,3,5.2,3,8 c0,2.8,2.2,5,5,5c2.8,0,5-2.2,5-5C13,5.2,10.8,3,8,3z" />
                    </svg>
                  </span>
                ) : (
                  <span className="indicator__area">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      className={`indicator__icon indicator__icon--open`}
                    >
                      <path d="M17.71,17.71L17.71,17.71c-0.387,0.387-1.013,0.387-1.4,0L10,11.4l-6.31,6.31c-0.387,0.387-1.013,0.387-1.4,0l0,0 c-0.387-0.387-0.387-1.013,0-1.4L8.6,10L2.29,3.69c-0.387-0.387-0.387-1.013,0-1.4l0,0c0.387-0.387,1.013-0.387,1.4,0L10,8.6 l6.31-6.31c0.387-0.387,1.013-0.387,1.4,0l0,0c0.387,0.387,0.387,1.013,0,1.4L11.4,10l6.31,6.31 C18.097,16.697,18.097,17.323,17.71,17.71z" />
                    </svg>
                  </span>
                )}
              </button>
              {isSearchOpen ? (
                <div className="indicator__dropdown">
                  <div className={`search search--location--indicator search--suggestions-open`}>
                    <div className="search__body">
                      <form className="search__form" action>
                        <input
                          className="search__input"
                          name="search"
                          placeholder="Search over 10,000 products"
                          aria-label="Site search"
                          type="text"
                          autoComplete="off"
                          autoFocus="true"
                          value={searchQuery}
                          onChange={handleInputSearchChange}
                        />
                        <button className="search__button search__button--type--submit" type="submit">
                          <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20}>
                            <path d="M19.2,17.8c0,0-0.2,0.5-0.5,0.8c-0.4,0.4-0.9,0.6-0.9,0.6s-0.9,0.7-2.8-1.6c-1.1-1.4-2.2-2.8-3.1-3.9C10.9,14.5,9.5,15,8,15 c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7c0,1.5-0.5,2.9-1.3,4c1.1,0.8,2.5,2,4,3.1C20,16.8,19.2,17.8,19.2,17.8z M8,3C5.2,3,3,5.2,3,8 c0,2.8,2.2,5,5,5c2.8,0,5-2.2,5-5C13,5.2,10.8,3,8,3z" />
                          </svg>
                        </button>
                        <div className="search__border" />
                      </form>
                      <div
                        className="suggestions suggestions--location--indicator search__suggestions"
                        style={{ display: showSearchSuggestions ? 'block' : 'none' }}
                      >
                        <ul className="suggestions__list">
                          {autocompleteResults.data.length > 0 ? (
                            autocompleteResults.data.map((item, key) => (
                              <li className="suggestions__item" key={key}>
                                <div className="suggestions__item-image product-image">
                                  <div className="product-image__body">
                                    <img
                                      className="product-image__img"
                                      src={
                                        getProductDetailsMultiple(autocompleteResults, item).media
                                          ? getProductDetailsMultiple(autocompleteResults, item).media
                                          : 'images/products/product-7.jpg'
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="suggestions__item-info">
                                  <a
                                    className="suggestions__item-name"
                                    onClick={() => {
                                      setIsSearchOpen(false);
                                      setSearchQuery('');
                                      setAutocompleteResults({ data: [] });
                                    }}
                                    href={`/product/${item.id}/${item.attributes['product.url']}`}
                                  >
                                    {item.attributes['product.label']}
                                  </a>
                                  {/* <div className="suggestions__item-meta">SKU: 83690/32</div> */}
                                </div>
                                <div className="suggestions__item-price">
                                  $
                                  {getProductDetailsMultiple(autocompleteResults, item).price
                                    ? getProductDetailsMultiple(autocompleteResults, item).price.attributes[
                                    'price.value'
                                    ]
                                    : '-'}
                                </div>
                              </li>
                            ))
                          ) : (
                            <li className="suggestions__item">No results found</li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* {cart.products.map((item, key) => ( */}
            {/* <Indicator url="/shop/wishlist" value={wishlist.length} icon={<Heart20Svg />} /> */}

            {/* <CartIndicator /> */}

            <div
              className={
                isCartOpen
                  ? 'indicator indicator--trigger--click undefined indicator--opened'
                  : 'indicator indicator--trigger--click undefined'
              }
              onClick={(e) => handleCartButton(e)}
            >
              <a className="indicator__button" href="/shop/cart">
                <span className="indicator__area">
                  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20}>
                    <circle cx={7} cy={17} r={2} />
                    <circle cx={15} cy={17} r={2} />
                    <path d="M20,4.4V5l-1.8,6.3c-0.1,0.4-0.5,0.7-1,0.7H6.7c-0.4,0-0.8-0.3-1-0.7L3.3,3.9C3.1,3.3,2.6,3,2.1,3H0.4C0.2,3,0,2.8,0,2.6 V1.4C0,1.2,0.2,1,0.4,1h2.5c1,0,1.8,0.6,2.1,1.6L5.1,3l2.3,6.8c0,0.1,0.2,0.2,0.3,0.2h8.6c0.1,0,0.3-0.1,0.3-0.2l1.3-4.4 C17.9,5.2,17.7,5,17.5,5H9.4C9.2,5,9,4.8,9,4.6V3.4C9,3.2,9.2,3,9.4,3h9.2C19.4,3,20,3.6,20,4.4z" />
                  </svg>
                  <span className="indicator__value">{cart.products.length}</span>
                </span>
              </a>
              <div className="indicator__dropdown">
                <div className="dropcart">
                  {cart.products.length > 0 ? (
                    <>
                      {' '}
                      <div className="dropcart__products-list">
                        {cart.products.map((item, key) => (
                          <div key={key} className="dropcart__product">
                            <div className="product-image dropcart__product-image">
                              <a className="product-image__body" href="/shop/products/Polenta and cured meats">
                                <img
                                  className="product-image__img"
                                  src={getMediaUrlFromString(item.attributes['order.product.mediaurl'])}
                                  alt=""
                                />
                              </a>
                            </div>
                            <div className="dropcart__product-info">
                              <div className="dropcart__product-name">
                                <a href="/shop/products/Polenta and cured meats">
                                  {item.attributes['order.product.name']}
                                  {item.variants &&
                                    item.variants.map((variant, key) => (
                                      <div key={key}>
                                        <label> {variant['variantLabel']}</label>{' '}
                                        <span>{variant['attribute.label']} </span>
                                      </div>
                                    ))}
                                </a>
                              </div>
                              <ul className="dropcart__product-options" />
                              <div className="dropcart__product-meta">
                                <span className="dropcart__product-quantity">
                                  {item.attributes['order.product.quantity']}
                                </span>{' '}
                                ×{' '}
                                <span className="dropcart__product-price">
                                  ${item.attributes['order.product.price']}
                                </span>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="dropcart__product-remove btn btn-light btn-sm btn-svg-icon"
                              onClick={(e) => handleRemoveCart(e, item.id)}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width={10} height={10}>
                                <path d="M8.8,8.8L8.8,8.8c-0.4,0.4-1,0.4-1.4,0L5,6.4L2.6,8.8c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4L3.6,5L1.2,2.6 c-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0L5,3.6l2.4-2.4c0.4-0.4,1-0.4,1.4,0l0,0c0.4,0.4,0.4,1,0,1.4L6.4,5l2.4,2.4 C9.2,7.8,9.2,8.4,8.8,8.8z" />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                      <div className="dropcart__totals">
                        <table>
                          <tbody>
                            <tr>
                              <th>Subtotal</th>
                              <td>${cart.details ? parseAmount(cart.details.attributes['order.price']) : '-'}</td>
                            </tr>
                            <tr>
                              <th>Tax</th>
                              <td>${cart.details ? parseAmount(cart.details.attributes['order.taxvalue']) : '-'}</td>
                            </tr>
                            {/* {cart.details.attributes['order.rebate'] > 0 ? (
                              <tr>
                                <th>Rebate</th>
                                <td>- ${parseAmount(cart.details.attributes['order.rebate'])}</td>
                              </tr>
                            ) : null} */}
                            <tr>
                              <th>Delivery</th>
                              <td>${parseAmount(cart.details.attributes['order.costs'])}</td>
                            </tr>
                            <tr>
                              <th>Total</th>
                              <td>
                                {' '}
                                ${cart.details ? parseAmount(cart.details.attributes['order.final_amount']) : '-'}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="dropcart__buttons">
                        <Link className="btn btn-secondary" to="/shop/cart">
                          View Cart
                        </Link>
                        <Link className="btn btn-primary" to="/shop/checkout">
                          Checkout
                        </Link>
                      </div>
                    </>
                  ) : (
                    <div className="dropcart__empty">Your shopping cart is empty!</div>
                  )}
                </div>
              </div>
            </div>
            <IndicatorAccount />
          </div>
        </div>
      </div>
    </div>
  );
}

NavPanel.propTypes = {
  /** one of ['default', 'compact'] (default: 'default') */
  layout: PropTypes.oneOf(['default', 'compact']),
  cartData: PropTypes.object,
};

NavPanel.defaultProps = {
  layout: 'default',
  cartData: [],
};

const mapStateToProps = (state) => ({
  wishlist: state.wishlist,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavPanel);
