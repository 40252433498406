// react
import React, { useEffect, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { API_PLACE_ORDER } from '../../config/endpoints';

// data stubs
import theme from '../../data/theme';
import { decryptString, getOrderPaymentStatus, parseAmount } from '../../services/helpers';

export default function AccountPageOrderDetails(props) {
  let { orderId } = useParams();

  orderId = decryptString(orderId);

  const [order, setOrder] = useState({ products: [], details: { attributes: [] }, order: [], address: [] });
  const [orderProducts, setOrderProducts] = useState([]);
  console.log(orderId);
  useEffect(() => {
    fetch(API_PLACE_ORDER + '?id=' + orderId + '&include=order/address,order/coupon,order/product,order/service', {
      method: 'GET',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data);
        const products = data.included.filter((product) => {
          console.log(product);
          return product.type === 'order/product';
        });

        const address = data.included.filter((product) => {
          console.log(product);
          return product.type === 'order/address';
        });

        setOrder((prevState) => ({
          ...prevState,
          products: products,
          address: address,
          details: data.data,
          order: data,
        }));
        console.log('Order', order);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Order Details — #${orderId}`}</title>
      </Helmet>

      <div className="card">
        <div className="order-header">
          <div className="order-header__actions">
            <Link to="/account/orders" className="btn btn-xs btn-secondary">
              Back to list
            </Link>
          </div>
          <h5 className="order-header__title">Order #{order.details.id}</h5>
          <div className="order-header__subtitle">
            Was placed on <mark className="order-header__date">{order.details.attributes['order.ctime']}</mark> and is
            currently{' '}
            <mark className="order-header__status">
              {getOrderPaymentStatus(order.details.attributes['order.statuspayment'])}
            </mark>
            .
          </div>
        </div>
        <div className="card-divider" />
        <div className="card-table">
          <div className="table-responsive-sm">
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody className="card-table__body card-table__body--merge-rows">
                {order.products.map((item, key) => (
                  <tr key={key}>
                    <td>
                      {item.attributes['order.product.name']} × {item.attributes['order.product.quantity']}
                    </td>
                    <td>${item.attributes['order.product.quantity'] * item.attributes['order.product.price']}</td>
                  </tr>
                ))}
              </tbody>
              <tbody className="card-table__body card-table__body--merge-rows">
                <tr>
                  <th>Subtotal</th>
                  <td>${order.details.attributes['order.price']}</td>
                </tr>
                <tr>
                  <th>Tax</th>
                  <td>${parseAmount(order.details.attributes['order.taxvalue'])}</td>
                </tr>
                <tr>
                  <th>Delivery</th>
                  <td>${parseAmount(order.details.attributes['order.costs'])}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Total</th>
                  <td>${parseAmount(order.details.attributes['order.final_amount'])}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <div className="row mt-3 no-gutters mx-n2 d-none">
        <div className="col-sm-6 col-12 px-2">
          <div className="card address-card address-card--featured">
            {order.address.map((address, key) => (
              <>
                <div key={key} className="addresses-list__item card address-card">
                  {address.default && <div className="address-card__badge">Default</div>}

                  <div className="address-card__body">
                    <div className="address-card__name">{`${address.attributes['customer.address.firstname']} ${address.attributes['customer.address.lastname']}`}</div>
                    <div className="address-card__row">
                      {address.attributes['customer.address.address1']}
                      <br />
                      {address.attributes['customer.address.city']}, {address.attributes['customer.address.state']},{' '}
                      {address.attributes['customer.address.postal']}
                      <br />
                      {address.attributes['customer.address.countryid']}
                    </div>
                    <div className="address-card__row">
                      <div className="address-card__row-title">Phone Number</div>
                      <div className="address-card__row-content">
                        {' '}
                        {address.attributes['customer.address.telephone']}
                      </div>
                    </div>
                    <div className="address-card__footer">
                      <Link to="/account/addresses/5">Edit</Link>
                      &nbsp;&nbsp;
                      <Link to="/">Remove</Link>
                    </div>
                  </div>
                </div>
                <div className="addresses-list__divider" />
              </>
            ))}
            {/* <div className="address-card__body">
              <div className="address-card__badge address-card__badge--muted">Shipping Address</div>
              <div className="address-card__name">Helena Garcia</div>
              <div className="address-card__row">
                Random Federation
                <br />
                115302, Moscow
                <br />
                ul. Varshavskaya, 15-2-178
              </div>
              <div className="address-card__row">
                <div className="address-card__row-title">Phone Number</div>
                <div className="address-card__row-content">38 972 588-42-36</div>
              </div>
              <div className="address-card__row">
                <div className="address-card__row-title">Email Address</div>
                <div className="address-card__row-content">stroyka@example.com</div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3 d-none">
          <div className="card address-card address-card--featured">
            <div className="address-card__body">
              <div className="address-card__badge address-card__badge--muted">Billing Address</div>
              <div className="address-card__name">Helena Garcia</div>
              <div className="address-card__row">
                Random Federation
                <br />
                115302, Moscow
                <br />
                ul. Varshavskaya, 15-2-178
              </div>
              <div className="address-card__row">
                <div className="address-card__row-title">Phone Number</div>
                <div className="address-card__row-content">38 972 588-42-36</div>
              </div>
              <div className="address-card__row">
                <div className="address-card__row-title">Email Address</div>
                <div className="address-card__row-content">stroyka@example.com</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
