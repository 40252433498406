const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export const API_LOGIN = `${baseUrl}/api/login`;
export const API_ME = `${baseUrl}/api/me`;
export const API_REGISTER = `${baseUrl}/api/register`;
export const API_PROFILE_UPDATE = `${baseUrl}/api/profile`;
export const API_GET_ADDRESS = `${baseUrl}/jsonapi/customer?related=address`;
export const API_GET_CATALOG = `${baseUrl}/jsonapi/catalog`;
export const API_GET_PRODUCTS = `${baseUrl}/jsonapi/product`;
export const API_GET_CART = `${baseUrl}/jsonapi/basket`;
export const API_PLACE_ORDER = `${baseUrl}/jsonapi/order`;
export const API_CART_CHECKOUT = `${baseUrl}/api/order/checkout`;
export const API_ORDER_UPDATE = `${baseUrl}/api/order/update`;
