import React from 'react';


const QuestionsPage = () => {
    return (
        <>
            <div className="page-header">
                <div className="page-header__container container">
                    <div className="page-header__breadcrumb">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                    {/* <i class="fa fa-angle-right breadcrumb-arrow"></i> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                                        <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                                    </svg>
                                </li>
                                {/* <li className="breadcrumb-item active">
                                    <a href="/">Shop</a>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                                        <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                                    </svg>
                                </li> */}
                                <li className="breadcrumb-item active">
                                    <a href="/questions-page">Frequently</a>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="accordion-list">
                <div className="container">
                    <div className="row">
                        <div className="accordion-heading-list">
                            <h1 className="mb-3">
                                Frequently Asked Questions
                            </h1>
                        </div>
                        <div className="col-12">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            HOW MUCH TO GET?
                                        </button>
                                    </div>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <h5>
                                                The choice is yours.
                                            </h5>
                                            <p>
                                                There’s nothing to worry about – really! Yes, you'll make the selection that’s right for you, and our butchers will ensure you get a “Cut Above” the rest. Whether it’s a quiet meal for two, or a party-size event at your cottage, we’ve assembled some guidelines to help you make the right choice for your order.
                                            </p>
                                            <h5>
                                                Adult and child serving sizes
                                            </h5>
                                            <p>
                                                We assume a typical adult eats about a 1/2 lb. (8 oz.) of meat in a meal featuring meat, and an "average" child eats about 1/4 lb. (4 oz.).
                                            </p>
                                            <p>
                                                So generally speaking, assume you get two adult servings, or four child servings, from every pound of meat. Note: this recommendation is based on the “average” size of what you'll see. For example, other grocery and butcher shops indicate 5-6 oz. is a serving -- this deflates their quoted price per serving, but we don't believe it's an accurate reflection of how much typical adults eat.
                                            </p>
                                            <p>
                                                We take a more curatorial approach by giving you a range of cuts -- we want to help you ensure you'll get enough servings per cut in each delivery to meet your dining and entertaining needs.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            THE FINAL SIZE OF MY STEAK SEAFOOD IS SLIGHTLY SMALLER/LARGER THAN SELECTED?
                                        </button>
                                    </div>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>
                                                Our butchers do their best to never deviate more than 5% from size you selected during ordering, which is why every product has a weight-range. We curate our meats and cheeses, therefore no two pieces are identical. Each product sold by weight and measure has their own story corresponding to the family and farm that cared for them. We try to make the imperfect perfect; that is our job. However, as trained butchers we know the cut is the most important part of the final meal. If there is a problem with your order, please let us know. The Cottage Butcher Delivery Insurance protects every cut of meat, seafood and cheese we sell.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            WHAT HAPPENS IF I REALLY DISLIKE A CUT YOU SENT ME?
                                        </button>
                                    </div>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                You can always give us a ring at the shop, and we'll be happy to replace it with something you like better. This rarely happens, since we're pretty diligent about each fresh cut of meat, seafood and cheese we send. And it is why we have The Cottage Butcher Delivery Insurance.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            HOW DOES COTTAGE BUTCHER DELIVERY INSURANCE PROGRAM WORK?
                                        </button>
                                    </div>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                Cottage Butcher Delivery Insurance Program covers all orders online, by phone, and via the Cottage Butcher app. If your delivery experience is not what you expected or you receive the wrong order, report the issue on the Cottage Butcher Tracker page, your order confirmation page, or through your order confirmation email. Tell us what went wrong, then choose from one of two options: either a coupon for 20% off your next order or 100 Cut Above Rewards® points. To receive the points, you must be a member of the Cottage Butcher Cut Above Rewards program — so if you’re not already a member just enroll within seven days of the date of your order to receive your 60 points and redeem them within the next 30 days for a free pizza from Cottage Butcher. Our teams makes it easy to make it right.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            CAN YOU COMBINE COUPON CODES AND/OR OFFERS?
                                        </button>
                                    </div>
                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                No. You can only apply one coupon code, or offer, or rewards points per order.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingSix">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            ARE THERE INCENTIVES FOR REFERRING A FRIEND?
                                        </button>
                                    </div>
                                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                Yes. Learn more <a href="/site/referringFriend">here</a>.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header" id="heading7">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                                            WHY ISN'T THE QUESTION I REALLY NEED AN ANSWER TO HERE???
                                        </button>
                                    </div>
                                    <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                Maybe it should be! Chat or email us your question and we'll answer it, and consider putting it here in FAQs for our community.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default QuestionsPage;
