import React from 'react';

const DeliveryInsurance = () => {
    return (
        <>
            <div className="page-header">
                <div className="page-header__container container">
                    <div className="page-header__breadcrumb">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                    {/* <i class="fa fa-angle-right breadcrumb-arrow"></i> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                                        <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                                    </svg>
                                </li>
                                {/* <li className="breadcrumb-item active">
                                    <a href="/">Shop</a>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                                        <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                                    </svg>
                                </li> */}
                                <li className="breadcrumb-item active">
                                    <a href="/delivery-insurance">Delivery Insurance Guarantee</a>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="delivery-insurance mt-4">
                <div className="container">
                    <div className="row">
                        <h1 className="mb-3">Cottage Butcher Delivery Guarantee</h1>
                        <h5>What Is Cottage Butcher Delivery Guarantee?</h5>
                        <p>
                            Our Cottage Butcher Delivery Guarantee Program is there to make sure your order is to the highest quality.
                            If you’re not completely satisfied with your Cottage Butcher delivery experience for whatever reason, our
                            team is committed to making it right.
                        </p>
                        <h5>When Can I Use Cottage Butcher Delivery Guarantee Program?</h5>
                        <p>
                            Whether you’ve invited to a friend’s cottage for the weekend or on your way up to enjoy the summer months,
                            Cottage Butcher has an assortment of unique savories and wide selection of high-quality meats, cheeses, and
                            made-to-order meals that satisfy everyone. Delivery simply adds convenience to deliciousness.
                        </p>
                        <p>
                            Placing your order with the Cottage Butcher means you also place your trust in us. Our team is ready to make
                            sure your order is ready and on its way - exactly the way you want it, with a friendly smile delivered right
                            to your door. Cottage Butcher Delivery Guarantee Program provides extra peace of mind.
                        </p>
                        <p>
                            Something wrong with your order? Simply let us know, and a Cottage Butcher team member will make it right.
                        </p>
                        <h5>How Does Cottage Butcher Delivery Guarantee Program Work?</h5>
                        <p>
                            Cottage Butcher Delivery Guarantee Program covers all orders online, by phone, and via the Cottage Butcher
                            app. If your delivery experience is not what you expected or you receive the wrong order, report the issue
                            on the Cottage Butcher Tracker page, your order confirmation page, or through your order confirmation email.
                            Tell us what went wrong, then choose from one of two options: either a coupon for 20% off your next order or
                            100 Cut Above Rewards® points. To receive the points, you must be a member of the Cottage Butcher Cut Above
                            Rewards program — so if you’re not already a member just enroll within seven days of the date of your order
                            to receive your 60 points and redeem them within the next 30 days for a free pizza from Cottage Butcher. Our
                            teams makes it easy to make it right.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DeliveryInsurance;
