// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import StroykaSlick from '../shared/StroykaSlick';

// data stubs
import theme from '../../data/theme';

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 379,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function SitePageAboutUs() {
    return (
        <>
            <div className="page-header">
                <div className="page-header__container container">
                    <div className="page-header__breadcrumb">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                    {/* <i class="fa fa-angle-right breadcrumb-arrow"></i> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                                        <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                                    </svg>
                                </li>
                                {/* <li className="breadcrumb-item active">
                                    <a href="/">Shop</a>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                                        <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                                    </svg>
                                </li> */}
                                <li className="breadcrumb-item active">
                                    <a href="/about-us">About</a>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="block about-us">
                <Helmet>
                    <title>{`About Us — ${theme.name}`}</title>
                </Helmet>

                <div className="about-us__image" />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-10">
                            <div className="about-us__body">
                                <h1 className="about-us__title">About Us</h1>
                                <div className="about-us__text typography">
                                    <p>
                                        For over 15 years, The Cottage Butcher has been proudly providing the
                                        Muskoka area with the finest quality craft-butchery products.
                                    </p>
                                    <p>
                                        This Spring, we’re reinventing that tradition with an even more elevated food
                                        experience. Sourcing only the best of the best – locally and globally.
                                        Specializing in high-end fine foods that aren’t available anywhere else.
                                        Coupled with ingredients inspired by the old-world. And culminating in one-
                                        of-a-kind recipes passed down for generations
                                    </p>
                                </div>
                                <div className="about-us__team">
                                    <h2 className="about-us__team-title">Meat Our Team</h2>
                                    <div className="about-us__team-subtitle text-muted">
                                        Want to work in our friendly team?
                                        <br />
                                        <Link to="/site/contact-us">Contact us</Link>
                                        {' '}
                                        and we will consider your candidacy.
                                    </div>
                                    <div className="about-us__teammates teammates">
                                        {/* <StroykaSlick {...slickSettings}> */}
                                        <div className="teammates__item teammate d-none">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-1.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Michael Russo</div>
                                            <div className="teammate__position text-muted">Chief Executive Officer</div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="../../../images/members/ivy.png" alt="" className="img-fluid" />
                                            </div>
                                            <div className="teammate__name">Ivy Fletcher</div>
                                            <div className="teammate__position text-muted">General Manager</div>
                                            <p className="outer-text-lvy">
                                                Ivy Fletcher
                                                Ivy has been involved in the food and beverage industry for 20 years in Ontario and British Columbia. Recently, Ivy finalized her career in oral and maxillo-facial surgery after 12 years as a surgical assistant. As the General Manager of The Cottage Butcher and Bistro, Ivy will provide her extensive level of customer service and professionalism skills to the company.
                                            </p>
                                        </div>
                                        <div className="teammates__item teammate d-none">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-3.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Anthony Harris</div>
                                            <div className="teammate__position text-muted">Finance Director</div>

                                        </div>
                                        {/* </StroykaSlick> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SitePageAboutUs;
