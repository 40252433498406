// react
import React, { useEffect, useMemo, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

import { API_GET_CART, API_GET_PRODUCTS } from '../../config/endpoints';
import { getItemDetails, getMediaUrlFromString, getProductDetails, getProductUrl } from '../../services/helpers';
import { Link } from 'react-router-dom';
import AddToCart from '../widgets/AddToCart';

function GroupProduct(props) {
  const { product, basePrice, onCartData } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [cart, setCart] = useState({ products: [], details: { attributes: [] } });
  const [variant, setVariant] = useState({});
  const [latestProducts, setLatestProducts] = useState([]);
  const [selectedQty, setSelectedQty] = useState(1);

  // Load product.
  useEffect(() => {
    // console.log('product from group', product);
    console.log('variant from group', variant);
  }, [variant]);

  if (isLoading) {
    return <BlockLoader />;
  }
  const handleVariantSelect = (item) => {
    console.log(item.product['product.id']);
    setVariant(item);
  };

  return (
    <React.Fragment>
      {/* <PageHeader breadcrumb={breadcrumb} /> */}
      <>
        <br></br>
        <div className="form-group product__option ">
          <div className="product__option-label">Select</div>
          <div className="input-radio-label">
            <div className="input-radio-label__list">
              {product.variants &&
                product.variants.map((item, key) =>
                  item.combinations.map((variant, key) => (
                    <label key={key}>
                      <input type="radio" name="material" onClick={() => handleVariantSelect(item)} />
                      <label> {variant['variantLabel']}</label> <span>{variant['attribute.label']} </span>
                    </label>
                  ))
                )}
            </div>
          </div>
        </div>

        <div className="product__prices">
          ${variant.price ? <>{variant.price['price.value']}</> : basePrice}
          {/* ${productDetails.price ? productDetails.price.attributes['price.value'] : ''} */}
        </div>
        {variant.product ? (
          <>
            <div className="form-group product__option">
              <label htmlFor="product-quantity" className="product__option-label">
                Quantity
              </label>
              <div className="product__actions">
                <div className="product__actions-item">
                  <div className="input-number product__quantity">
                    <input
                      className="form-control input-number__input form-control-lg"
                      type="number"
                      id="product-quantity"
                      aria-label="Quantity"
                      min={1}
                      step={1}
                      defaultValue={selectedQty}
                      value={selectedQty}
                    />
                    <div className="input-number__add" onClick={(e) => setSelectedQty(selectedQty + 1)} />
                    <div
                      className="input-number__sub"
                      onClick={selectedQty === 1 ? null : (e) => setSelectedQty(selectedQty - 1)}
                    />
                  </div>
                </div>
                <div className="product__actions-item product__actions-item--addtocart">
                  <AddToCart
                    product={variant}
                    selectedQty={selectedQty}
                    onUpdateCart={onCartData}
                    groupProduct={true}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <p>Please select a variant to add to your cart.</p>
        )}
      </>
    </React.Fragment>
  );
}

export default GroupProduct;
