import React from 'react';
import { Link } from 'react-router-dom';
// blocks
// import BlockMap from '../blocks/BlockMap';

const PasswordForget = () => {
    return (
        <>
            <div className="block block-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <h3 className="card-title">Reset Password</h3>
                                    <form>

                                        <label for="email-user" class="form-label">Email address</label>
                                        <input type="email" class="form-control" id="email-user" placeholder="name@example.com" />


                                        <Link to="/site/reset-password" className="btn btn-primary mt-2 mt-md-3 mt-lg-4" type="submit">
                                            Reset Password
                                        </Link>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
};

export default PasswordForget;
