// react
import React, { useEffect } from 'react';

// third-party
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// application
import NavPanel from './NavPanel';
import Search from './Search';
import Topbar from './Topbar';
import { LogoSvg } from '../../svg';

function Header(props) {
  const { layout, cartData } = props;
  let bannerSection;

  useEffect(() => {
    // console.log('cart', cartData);
  }, [cartData]);

  if (layout === 'default') {
    bannerSection = (
      <div className="site-header__middle container">
        <div className="site-header__logo">
          <Link to="/">
            <LogoSvg />
          </Link>
        </div>
        <div className="site-header__search">
          <Search context="header" />
        </div>
        <div className="site-header__phone">
          <div className="site-header__phone-title">
            <FormattedMessage id="header.phoneLabel" defaultMessage="Customer Service" />
          </div>
          <div className="site-header__phone-number">
            <FormattedMessage id="header.phone" defaultMessage="705 762 5995" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="site-header">
      <Topbar />
      {bannerSection}
      <div className="site-header__nav-panel">
        <NavPanel layout={layout} cartData={cartData} />
      </div>
    </div>
  );
}

Header.propTypes = {
  /** one of ['default', 'compact'] (default: 'default') */
  layout: PropTypes.oneOf(['default', 'compact']),
  cartData: PropTypes.object,
};

Header.defaultProps = {
  layout: 'default',
  cartData: [],
};

export default Header;
