// react
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';

// data stubs
import order from '../../data/accountOrderDetails';
import theme from '../../data/theme';
import { url } from '../../services/utils';
import { API_PLACE_ORDER } from '../../config/endpoints';
import { decryptString, encryptString, getMediaUrlFromString, parseAmount } from '../../services/helpers';

export default function ShopPageOrderSuccess() {
  let { orderId } = useParams();
  const [order, setOrder] = useState({ products: [], details: { attributes: [] }, order: [], address: [] });
  orderId = decryptString(orderId);
  useEffect(() => {
    fetch(API_PLACE_ORDER + '?id=' + orderId + '&include=order/address,order/coupon,order/product,order/service', {
      method: 'GET',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data);
        const products = data.included.filter((product) => {
          console.log(product);
          return product.type === 'order/product';
        });

        const address = data.included.filter((product) => {
          console.log(product);
          return product.type === 'order/address';
        });

        setOrder((prevState) => ({
          ...prevState,
          products: products,
          address: address,
          details: data.data,
          order: data,
        }));
        console.log('Order', order);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="block order-success">
      <Helmet>
        <title>{`Order Success — ${theme.name}`}</title>
      </Helmet>

      <div className="container">
        <div className="order-success__body">
          <div className="order-success__header">
            <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} className="order-success__icon">
              <path d="M50,100C22.4,100,0,77.6,0,50S22.4,0,50,0s50,22.4,50,50S77.6,100,50,100z M50,2C23.5,2,2,23.5,2,50 s21.5,48,48,48s48-21.5,48-48S76.5,2,50,2z M44.2,71L22.3,49.1l1.4-1.4l21.2,21.2l34.4-34.4l1.4,1.4L45.6,71 C45.2,71.4,44.6,71.4,44.2,71z" />
            </svg>
            <h1 className="order-success__title">Thank you</h1>
            <div className="order-success__subtitle">Your order has been received</div>
            <div className="order-success__actions">
              <a className="btn btn-xs btn-secondary" href="/">
                Go To Homepage
              </a>
            </div>
          </div>
          <div className="order-success__meta">
            <ul className="order-success__meta-list">
              <li className="order-success__meta-item">
                <span className="order-success__meta-title">Order number:</span>
                <span className="order-success__meta-value">#{orderId}</span>
              </li>
              <li className="order-success__meta-item">
                <span className="order-success__meta-title">Created at:</span>
                <span className="order-success__meta-value">{order.details.attributes['order.ctime']}</span>
              </li>
              <li className="order-success__meta-item">
                <span className="order-success__meta-title">Total:</span>
                <span className="order-success__meta-value">${order.details.attributes['order.final_amount']}</span>
              </li>
              <li className="order-success__meta-item">
                <span className="order-success__meta-title">Payment method:</span>
                <span className="order-success__meta-value">{order.details.attributes['payment_method']}</span>
              </li>
            </ul>
          </div>
          <div className="card">
            <div className="order-list">
              <table>
                <thead className="order-list__header">
                  <tr>
                    <th className="order-list__column-label" colSpan={2}>
                      Product
                    </th>
                    <th className="order-list__column-quantity">Qty</th>
                    <th className="order-list__column-total">Total</th>
                  </tr>
                </thead>
                <tbody className="order-list__products">
                  {order.products.map((item, key) => (
                    <tr key={key}>
                      <td className="order-list__column-image">
                        <div className="product-image">
                          <a
                            className="product-image__body"
                            href="/shop/products/electric-planer-brandix-kl370090g-300-watts"
                          >
                            <img
                              className="product-image__img"
                              src={getMediaUrlFromString(item.attributes['order.product.mediaurl'])}
                              alt=""
                            />
                          </a>
                        </div>
                      </td>
                      <td className="order-list__column-product">
                        <a href="/shop/products/electric-planer-brandix-kl370090g-300-watts">
                          {item.attributes['order.product.name']}
                        </a>
                        <div className="order-list__options d-none">
                          <ul className="order-list__options-list">
                            <li className="order-list__options-item">
                              <span className="order-list__options-label">Color: </span>
                              <span className="order-list__options-value">Yellow</span>
                            </li>
                            <li className="order-list__options-item">
                              <span className="order-list__options-label">Material: </span>
                              <span className="order-list__options-value">Aluminium</span>
                            </li>
                          </ul>
                        </div>
                      </td>
                      <td className="order-list__column-quantity" data-title="Qty:">
                        {item.attributes['order.product.quantity']}
                      </td>
                      <td className="order-list__column-total">
                        $
                        {parseAmount(
                          item.attributes['order.product.quantity'] * item.attributes['order.product.price']
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tbody className="order-list__subtotals">
                  <tr>
                    <th className="order-list__column-label" colSpan={3}>
                      Subtotal
                    </th>
                    <td className="order-list__column-total">${order.details.attributes['order.price']}</td>
                  </tr>
                  <tr>
                    <th className="order-list__column-label" colSpan={3}>
                      Tax
                    </th>
                    <td className="order-list__column-total">
                      ${parseAmount(order.details.attributes['order.taxvalue'])}
                    </td>
                  </tr>
                  <tr>
                    <th className="order-list__column-label" colSpan={3}>
                      Delivery
                    </th>
                    <td className="order-list__column-total">
                      ${parseAmount(order.details.attributes['order.costs'])}
                    </td>
                  </tr>
                </tbody>
                <tfoot className="order-list__footer">
                  <tr>
                    <th className="order-list__column-label" colSpan={3}>
                      Total
                    </th>
                    <td className="order-list__column-total">
                      ${parseAmount(order.details.attributes['order.final_amount'])}
                      <img
                        src={`https://partner.ordercottagebutcher.com/scripts/svf42v1qkw?AccountId=default1&TotalCost=${parseAmount(
                          order.details.attributes['order.final_amount']
                        )}&OrderID=${orderId}`}
                        width="1"
                        height="1"
                      />
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div className="row mt-3 no-gutters mx-n2 d-none">
            <div className="col-sm-6 col-12 px-2">
              <div className="card address-card">
                <div className="address-card__body">
                  <div className="address-card__badge address-card__badge--muted">Shipping Address</div>
                  <div className="address-card__name">Helena Garcia</div>
                  <div className="address-card__row">
                    Random Federation <br />
                    115302, Moscow <br />
                    ul. Varshavskaya, 15-2-178{' '}
                  </div>
                  <div className="address-card__row">
                    <div className="address-card__row-title">Phone Number</div>
                    <div className="address-card__row-content">38 972 588-42-36</div>
                  </div>
                  <div className="address-card__row">
                    <div className="address-card__row-title">Email Address</div>
                    <div className="address-card__row-content">stroyka@example.com</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
              <div className="card address-card">
                <div className="address-card__body">
                  <div className="address-card__badge address-card__badge--muted">Billing Address</div>
                  <div className="address-card__name">Jupiter Saturnov</div>
                  <div className="address-card__row">
                    RandomLand <br />
                    4b4f53, MarsGrad <br />
                    Sun Orbit, 43.3241-85.239{' '}
                  </div>
                  <div className="address-card__row">
                    <div className="address-card__row-title">Phone Number</div>
                    <div className="address-card__row-content">ZX 971 972-57-26</div>
                  </div>
                  <div className="address-card__row">
                    <div className="address-card__row-title">Email Address</div>
                    <div className="address-card__row-content">stroyka@example.com</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
