export default [
    {
        name: 'INGREDIENTS ',
        features: [
            { name: '1 tsp salt', value: '' },
            { name: '2 tsp pure vanilla extract', value: '' },
            { name: 'Tbsp peanut butter', value: '' },
            { name: 'c.almond milk', value: ' ' },
            { name: '1-2 Tbsp maple syrup (optional)', value: '' },
            { name: 'Wandering Bear cold brew coffee', value: '' },
        ],
    },
    {
        name: 'NUTRITIONAL INFO',
        features: [
            { name: 'Calories/serving', value: '96 dietary calories' },
            { name: 'Carbs/serving', value: '23g' },
            { name: 'Protein/serving', value: '0g' },
            { name: 'Fat/serving', value: '0g' },
            { name: 'Sat Fat/serving', value: '0g' },
            { name: 'Sodium/serving', value: '0mg' },
            { name: 'Fiber/serving', value: '0g' },

        ],
    },
];
