// react
import React, { useState, useRef } from 'react';

// third-party
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { ShowToast } from '../../services/helpers';

// application
import Indicator from './Indicator';
import { Person20Svg } from '../../svg';
import { API_LOGIN } from '../../config/endpoints';
import { getUserData, setUserData, logoutUser } from '../../services/storage';
const loggedInUser = getUserData();

export default function IndicatorAccount() {
  const [isBtnLoading, setIsBtnLoading] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const handleLogin = async (e) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsBtnLoading(true);
      console.log(email);
      console.log(password);
      await fetch(API_LOGIN, {
        method: 'POST',
        crossDomain: true,
        credentials: 'include',
        body: JSON.stringify({ email, password }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          console.log(data.success);
          if (!data.success) {
            ShowToast('error', data.message);
          } else {
            ShowToast('success', 'Logged In.');
            setUserData(data);
            window.location = '';
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setIsBtnLoading(false);
    }
  };

  const dropdown = (
    <div className="account-menu">
      {!loggedInUser.isLoggedIn ? (
        <>
          <form className="account-menu__form">
            <div className="account-menu__form-title">Log In to Your Account</div>
            <div className="form-group">
              <label htmlFor="header-signin-email" className="sr-only">
                Email address
              </label>
              <input
                id="header-signin-email"
                type="email"
                className="form-control form-control-sm"
                placeholder="Email address"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => simpleValidator.current.showMessageFor('email')}
              />
              {simpleValidator.current.message('email', email, 'required|email')}
            </div>
            <div className="form-group">
              <label htmlFor="header-signin-password" className="sr-only">
                Password
              </label>
              <div className="account-menu__form-forgot">
                <input
                  id="header-signin-password"
                  type="password"
                  className="form-control form-control-sm"
                  placeholder="Password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={() => simpleValidator.current.showMessageFor('password')}
                />
                {simpleValidator.current.message('password', password, 'required|min:8')}
                <Link to="/account/login" className="account-menu__form-forgot-link">
                  Forgot?
                </Link>
              </div>
            </div>
            <div className="form-group account-menu__form-button">
              <button
                type="submit"
                disabled={isBtnLoading}
                className="btn btn-primary btn-sm"
                onClick={(e) => handleLogin(e)}
              >
                Login
                <i className={isBtnLoading ? `fa fa-spin fa-spinner` : ``} />
              </button>
            </div>
            <div className="account-menu__form-link">
              <Link to="/account/login">Create An Account</Link>
            </div>
          </form>
        </>
      ) : (
        <>
          <div className="account-menu__divider" />
          <Link to="/account/dashboard" className="account-menu__user">
            <div className="account-menu__user-avatar">
              <img src="images/avatars/avatar-3.jpg" alt="" />
            </div>
            <div className="account-menu__user-info">
              <div className="account-menu__user-name">
                {loggedInUser.user.firstname} {loggedInUser.user.lastname}
              </div>
              <div className="account-menu__user-email">{loggedInUser.user.email}</div>
            </div>
          </Link>
          <div className="account-menu__divider" />
          <ul className="account-menu__links">
            <li>
              <Link to="/account/profile">Edit Profile</Link>
            </li>
            <li>
              <Link to="/account/orders">Order History</Link>
            </li>
            <li>
              <Link to="/account/addresses">Addresses</Link>
            </li>
            <li>
              <Link to="/account/password">Password</Link>
            </li>
          </ul>
          <div className="account-menu__divider" />
          <ul className="account-menu__links">
            <li>
              <Link to="/" onClick={logoutUser}>
                Logout
              </Link>
            </li>
          </ul>
        </>
      )}
    </div>
  );

  return <Indicator url="/account" dropdown={dropdown} icon={<Person20Svg />} />;
}
