// react
import React, { useEffect, useMemo, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// application

// blocks
import BlockLoader from '../blocks/BlockLoader';

// widgets
import { API_GET_CART, API_GET_PRODUCTS } from '../../config/endpoints';
import {
  getItemDetails,
  getMediaUrlFromString,
  getProductDetails,
  getProductDetailsMultiple,
  getProductUrl,
  urlToText,
} from '../../services/helpers';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import AddToCart from '../widgets/AddToCart';

function CategoryPage(props) {
  const { onCartData } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [latestProducts, setLatestProducts] = useState({ data: [] });
  const [products, setProducts] = useState({ data: [] });
  const { categoryId, url } = useParams();

  const getLatestProducts = () => {
    fetch(API_GET_PRODUCTS + '?filter[f_catid]=' + process.env.REACT_APP_BESTSELLER_ID + '&include=media,price', {
      method: 'GET',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setLatestProducts(data);
        // setCategoriesList(data.included);
        console.log(latestProducts);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProducts = () => {
    setIsLoading(true);
    fetch(
      API_GET_PRODUCTS +
      '?sort=product%2Elabel&filter[%26%26][][%21%3D][product.mode]=POS&filter[f_catid]=' +
      categoryId +
      '&include=media,price',
      {
        method: 'GET',
        crossDomain: true,
        credentials: 'include',
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setProducts(data);
        // setCategoriesList(data.included);
        console.log(latestProducts);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProducts();
    getLatestProducts();
  }, []);

  if (isLoading) {
    return <BlockLoader />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${urlToText(url)} — Cottage Butcher`}</title>
      </Helmet>
      <div className="site__body">
        <div className="page-header">
          <div className="page-header__container container">
            <div className="page-header__breadcrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                    <svg xmlns="http://www.w3.org/2000/svg" width={6} height={9} className="breadcrumb-arrow">
                      <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                    </svg>
                  </li>
                  <a href="/shop/categories">
                    <li className="breadcrumb-item active" aria-current="page">
                      Categories
                      <svg xmlns="http://www.w3.org/2000/svg" width={6} height={9} className="breadcrumb-arrow">
                        <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                      </svg>
                    </li>
                  </a>
                  <li className="breadcrumb-item active" aria-current="page">
                    {urlToText(url)}
                  </li>
                </ol>
              </nav>
            </div>
            <div className="page-header__title">
              <h1>{urlToText(url)}</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="shop-layout shop-layout--sidebar--start">
            {isLoading ? (
              <></>
            ) : (
              <div className="shop-layout__content">
                <div className="block">
                  <div className="products-view">
                    <div className="products-view__loader" />
                    <div className="products-view__content">
                      <div className="products-view__options">
                        <div className="view-options view-options--offcanvas--mobile">
                          <div className="view-options__filters-button d-none">
                            <button type="button" className="filters-button">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                className="filters-button__icon"
                              >
                                <path d="M7,14v-2h9v2H7z M14,7h2v2h-2V7z M12.5,6C12.8,6,13,6.2,13,6.5v3c0,0.3-0.2,0.5-0.5,0.5h-2 C10.2,10,10,9.8,10,9.5v-3C10,6.2,10.2,6,10.5,6H12.5z M7,2h9v2H7V2z M5.5,5h-2C3.2,5,3,4.8,3,4.5v-3C3,1.2,3.2,1,3.5,1h2 C5.8,1,6,1.2,6,1.5v3C6,4.8,5.8,5,5.5,5z M0,2h2v2H0V2z M9,9H0V7h9V9z M2,14H0v-2h2V14z M3.5,11h2C5.8,11,6,11.2,6,11.5v3 C6,14.8,5.8,15,5.5,15h-2C3.2,15,3,14.8,3,14.5v-3C3,11.2,3.2,11,3.5,11z" />
                              </svg>
                              <span className="filters-button__title">Filters</span>
                            </button>
                          </div>

                          <div className="view-options__legend">
                            Showing 1—{products.data.length} of {products.data.length} products
                          </div>
                          <div className="view-options__divider" />
                          <div className="view-options__control d-none">
                            <label htmlFor="view-options-sort">Sort By</label>
                            <div>
                              <select id="view-options-sort" className="form-control form-control-sm">
                                <option value="default">Default</option>
                                <option value="name_asc">Name (A-Z)</option>
                                <option value="name_desc">Name (Z-A)</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="products-view__list products-list"
                        data-layout="grid-3-sidebar"
                        data-with-features="false"
                      >
                        <div className="products-list__body">
                          {products.data.map((item, key) => (
                            <div className="products-list__item" key={key}>
                              <div className="product-card">
                                <div className="product-card__image product-image">
                                  <Link
                                    className="product-image__body"
                                    to={`/product/${item.id}/${item.attributes['product.url']}`}
                                  >
                                    <img
                                      className="product-image__img"
                                      src={
                                        getProductDetailsMultiple(products, item).media
                                          ? getProductDetailsMultiple(products, item).media
                                          : 'images/products/product-7.jpg'
                                      }
                                      alt=""
                                    />
                                  </Link>
                                </div>
                                <div className="product-card__info">
                                  <div className="product-card__name">
                                    <Link to={`/product/${item.id}/${item.attributes['product.url']}`}>
                                      {item.attributes['product.label']}
                                    </Link>
                                  </div>
                                </div>
                                <div className="product-card__actions">
                                  <div className="product-card__prices">
                                    {' '}
                                    $
                                    {getProductDetailsMultiple(products, item).price
                                      ? getProductDetailsMultiple(products, item).price.attributes['price.value']
                                      : '-'}
                                  </div>
                                  <div className="product-card__buttons">
                                    {item.attributes['product.type'] === 'group' ? (
                                      <>
                                        <Link
                                          to={`/product/${item.id}/${item.attributes['product.url']}`}
                                          type="button"
                                          className="btn btn-primary btn-lg"
                                        >
                                          View
                                        </Link>
                                      </>
                                    ) : (
                                      <AddToCart product={item} selectedQty={1} onUpdateCart={onCartData} />
                                    )}

                                    <button
                                      type="button"
                                      className="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare d-none"
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16}>
                                        <path d="M9,15H7c-0.6,0-1-0.4-1-1V2c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1v12C10,14.6,9.6,15,9,15z" />
                                        <path d="M1,9h2c0.6,0,1,0.4,1,1v4c0,0.6-0.4,1-1,1H1c-0.6,0-1-0.4-1-1v-4C0,9.4,0.4,9,1,9z" />
                                        <path d="M15,5h-2c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1V6C16,5.4,15.6,5,15,5z" />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

CategoryPage.propTypes = {
  /** Product slug. */
  productSlug: PropTypes.string,
  /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
  layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
  /**
   * sidebar position (default: 'start')
   * one of ['start', 'end']
   * for LTR scripts "start" is "left" and "end" is "right"
   */
  sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

CategoryPage.defaultProps = {
  layout: 'standard',
  sidebarPosition: 'start',
};

export default CategoryPage;
