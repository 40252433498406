export default [
    {
        type: 'link',
        label: 'Home',
        url: '/',
        children: [
            // { type: 'link', label: 'Home 1', url: '/' },
            // { type: 'link', label: 'Home 2', url: '/home-two' },
        ],
    },

    {
        type: 'link',
        label: 'Categories',
        url: '',
        children: [
            {
                type: 'link',
                label: 'Vegetarian',
                url: '',
                children: [
                    // { type: 'link', label: 'Idli', url: '/product/131/idli' },
                    // { type: 'link', label: 'Gobi Manchurian', url: '/product/132/gobi-manchurian' },
                    // { type: 'link', label: 'Sandwich', url: '/product/133/sandwich' },
                    // { type: 'link', label: 'Kadhi Pakora', url: '/product/134/kadhi-pakora' },
                ],
            },
            {
                type: 'link',
                label: 'Non- Veg',
                url: '',
                children: [
                    // { type: 'link', label: 'Chicken Thigh', url: '/product/57/chicken-thigh' },
                    // { type: 'link', label: 'Chicken Drum Sticks', url: '/product/59/chicken-drum-sticks' },
                    // { type: 'link', label: 'Chicken Mince', url: '/product/61/chicken-mince' },
                    // { type: 'link', label: 'Rohu', url: '/product/84/rohu' },
                    // { type: 'link', label: 'River Sol', url: '/product/92/river-sol' },
                    // { type: 'link', label: 'Base', url: '/product/94/basa' },
                ],
            },
            {
                type: 'link',
                label: 'Italian',
                url: '',
                children: [
                    // { type: 'link', label: 'Pizza', url: '/product/114/pizza' },
                    // { type: 'link', label: 'Polenta', url: '/product/116/polenta' },
                    // { type: 'link', label: 'Cotoletta', url: '/product/118/cotoletta' },
                    // { type: 'link', label: 'Baccal', url: '/product/120/baccal' },
                    // { type: 'link', label: 'Slotting machines', url: '' },
                    // { type: 'link', label: 'Lathes', url: '' },
                ],
            },
            {
                type: 'link',
                label: 'Asian',
                url: '',
                children: [
                    // { type: 'link', label: 'Sushi', url: '/product/122/sushi' },
                    // { type: 'link', label: 'Kimchi', url: '/product/124/kimchi' },
                    // { type: 'link', label: 'Biryani', url: '/product/126/biryani' },
                    // { type: 'link', label: 'Satay', url: '/product/128/satay' },
                    // { type: 'link', label: 'Ramen', url: '/product/130/ramen' },
                    // { type: 'link', label: 'Lathes', url: '' },
                ],
            },
            {
                type: 'link',
                label: 'Chinese',
                url: '',
                children: [
                    // { type: 'link', label: 'DimSum', url: '/product/96/dimsum' },
                    // { type: 'link', label: 'Dumplings', url: '/product/105/dumplings' },
                    // { type: 'link', label: 'Ma Po Tofu', url: '/product/107/ma-po-tofu' },
                    // { type: 'link', label: 'Chow Mein', url: '/product/110/chow-mein' },
                    // { type: 'link', label: 'Fried Rice', url: '/product/112/fried-rice' },
                    // { type: 'link', label: 'Lathes', url: '' },
                ],
            },
            {
                type: 'link',
                label: 'Best Sellers',
                url: '',
                children: [
                    { type: 'link', label: 'Chicken', url: '' },
                    { type: 'link', label: 'Chicken Thigh', url: '' },
                    { type: 'link', label: 'Rohu', url: '' },
                    { type: 'link', label: 'Rive Sol', url: '' },
                    { type: 'link', label: 'Ma Po Tofu', url: '' },
                    // { type: 'link', label: 'Lathes', url: '' },
                ],
            },
        ],
    },

    // {
    //     type: 'link',
    //     label: 'Shop',
    //     url: '/shop/category-grid-3-columns-sidebar',
    //     children: [
    //         {
    //             type: 'link',
    //             label: 'Shop Grid',
    //             url: '/shop/category-grid-3-columns-sidebar',
    //             children: [
    //                 { type: 'link', label: '3 Columns Sidebar', url: '/shop/category-grid-3-columns-sidebar' },
    //                 { type: 'link', label: '4 Columns Full', url: '/shop/category-grid-4-columns-full' },
    //                 { type: 'link', label: '5 Columns Full', url: '/shop/category-grid-5-columns-full' },
    //             ],
    //         },
    //         { type: 'link', label: 'Shop List', url: '/shop/category-list' },
    //         { type: 'link', label: 'Shop Right Sidebar', url: '/shop/category-right-sidebar' },
    //         {
    //             type: 'link',
    //             label: 'Product',
    //             url: '/shop/product-standard',
    //             children: [
    //                 { type: 'link', label: 'Product', url: '/shop/product-standard' },
    //                 { type: 'link', label: 'Product Alt', url: '/shop/product-columnar' },
    //                 { type: 'link', label: 'Product Sidebar', url: '/shop/product-sidebar' },
    //             ],
    //         },
    //         { type: 'link', label: 'Cart', url: '/shop/cart' },
    //         { type: 'link', label: 'Checkout', url: '/shop/checkout' },
    //         { type: 'link', label: 'Order Success', url: '/shop/checkout/success' },
    //         { type: 'link', label: 'Wishlist', url: '/shop/wishlist' },
    //         { type: 'link', label: 'Compare', url: '/shop/compare' },
    //         { type: 'link', label: 'Track Order', url: '/shop/track-order' },
    //     ],
    // },

    {
        type: 'link',
        label: 'Account',
        url: '/account',
        children: [
            { type: 'link', label: 'Login', url: '/account/login' },
            { type: 'link', label: 'Dashboard', url: '/account/dashboard' },
            { type: 'link', label: 'Edit Profile', url: '/account/profile' },
            { type: 'link', label: 'Order History', url: '/account/orders' },
            { type: 'link', label: 'Order Details', url: '/account/orders/5' },
            { type: 'link', label: 'Address Book', url: '/account/addresses' },
            { type: 'link', label: 'Edit Address', url: '/account/addresses/5' },
            { type: 'link', label: 'Change Password', url: '/account/password' },
        ],
    },

    {
        type: 'link',
        label: 'Best Offer',
        url: '/shop/category-grid-3-columns-sidebar',
        children: [
            { type: 'link', label: 'Today Best Deals', url: '/blog/category-classic' },
            { type: 'link', label: 'Most Ordered', url: '/blog/category-grid' },
            { type: 'link', label: 'Today special', url: '/blog/category-list' },
            { type: 'link', label: 'Extra Discount', url: '/blog/category-left-sidebar' },
            // { type: 'link', label: 'Post Page', url: '/blog/post-classic' },
            // { type: 'link', label: 'Post Without Sidebar', url: '/blog/post-full' },
        ],
    },

    // {
    //     type: 'link',
    //     label: 'Pages',
    //     url: '/site/about-us',
    //     children: [
    //         { type: 'link', label: 'About Us', url: '/site/about-us' },
    //         { type: 'link', label: 'Contact Us', url: '/site/contact-us' },
    //         { type: 'link', label: 'Contact Us Alt', url: '/site/contact-us-alt' },
    //         { type: 'link', label: '404', url: '/site/not-found' },
    //         { type: 'link', label: 'Terms And Conditions', url: '/site/terms' },
    //         { type: 'link', label: 'FAQ', url: '/site/faq' },
    //         { type: 'link', label: 'Components', url: '/site/components' },
    //         { type: 'link', label: 'Typography', url: '/site/typography' },
    //     ],
    // },

    // {
    //     type: 'button',
    //     label: 'Currency',
    //     children: [
    //         { type: 'button', label: '€ Euro', data: { type: 'currency', code: 'EUR' } },
    //         { type: 'button', label: '£ Pound Sterling', data: { type: 'currency', code: 'GBP' } },
    //         { type: 'button', label: '$ US Dollar', data: { type: 'currency', code: 'USD' } },
    //         { type: 'button', label: '₽ Russian Ruble', data: { type: 'currency', code: 'RUB' } },
    //     ],
    // },

    // {
    //     type: 'button',
    //     label: 'Language',
    //     children: [
    //         { type: 'button', label: 'English', data: { type: 'language', locale: 'en' } },
    //         { type: 'button', label: 'Russian', data: { type: 'language', locale: 'ru' } },
    //     ],
    // },
];
