import React from 'react';
import { Link } from 'react-router-dom';
// blocks
// import BlockMap from '../blocks/BlockMap';

const ResetPassword = () => {
    return (
        <>
            <div className="block block-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <h3 className="card-title">Reset Password</h3>
                                    <form>
                                        <label for="forget-password" class="form-label">Enter New Password</label>
                                        <input type="password" class="form-control mb-2" id="forget-password" placeholder="Enter New Password" />
                                        <label for="confirm-password" class="form-label">Confirm Password</label>
                                        <input type="password" class="form-control" id="confirm-password" placeholder="Confirm Password" />

                                        <Link to="/site/reset-password" className="btn btn-primary mt-2 mt-md-3 mt-lg-4" type="submit">
                                            Change Password
                                        </Link>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
};

export default ResetPassword;
