import React from 'react'

const PickUpOrder = () => {
    return (
        <>
            <div className="page-header">
                <div className="page-header__container container">
                    <div className="page-header__breadcrumb">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                    {/* <i class="fa fa-angle-right breadcrumb-arrow"></i> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                                        <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                                    </svg>
                                </li>
                                {/* <li className="breadcrumb-item active">
                                    <a href="/">Shop</a>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                                        <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                                    </svg>
                                </li> */}
                                <li className="breadcrumb-item active">
                                    <a href="/pick-up-order">Pick-Up Orders</a>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="pick-up-order">
                <div className="container">
                    <div className="row">
                        <h1 className="mb-3">
                            Pick-Up Orders
                        </h1>
                        <p>
                            Please allow at least two hours for your pick-up order to be ready in store. This gives us time to ensure the quality and completeness of each customer’s order and serve those who are already in-store with care and courtesy.
                        </p>
                        <p>
                            For “Cut Above Rewards” Members, we can have your order ready for FREE when you schedule your pick-up ahead of time. We will let you know your order is ready by SMS or email.
                        </p>
                        <p>
                            If you need your order within 2 hours, we charge $5.99 for RUSH orders. This fee is always waived for our Elite and Reserved “Cut Above Rewards” Members.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
};

export default PickUpOrder;
