// react
import React, { useEffect, useState, useRef } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import SimpleReactValidator from 'simple-react-validator';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
// application
import PageHeader from '../shared/PageHeader';
import Product from '../shared/Product';
import ProductTabs from './ProductTabs';
import shopApi from '../../api/shop';
import { url } from '../../services/utils';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import DatePicker from 'react-datepicker';
// import postalCodes from '../../data/postalCodes';
import 'react-datepicker/dist/react-datepicker.css';
import {
  setHours,
  setMinutes,
  isPast,
  isSameDay,
  addHours,
  addDays,
  addMinutes,
  format,
  differenceInMinutes,
} from 'date-fns';

// blocks
import BlockLoader from '../blocks/BlockLoader';
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';

// widgets
import WidgetCategories from '../widgets/WidgetCategories';
import WidgetProducts from '../widgets/WidgetProducts';

// data stubs
import categories from '../../data/shopWidgetCategories';
import theme from '../../data/theme';
import {
  API_CART_CHECKOUT,
  API_GET_ADDRESS,
  API_GET_CART,
  API_GET_PRODUCTS,
  API_ORDER_UPDATE,
  API_PLACE_ORDER,
} from '../../config/endpoints';
import {
  encryptString,
  getItemDetails,
  getMediaUrlFromString,
  getOrderTypeValue,
  getProductDetails,
  parseAmount,
  ShowToast,
  checkFilterCodes,
} from '../../services/helpers';
import { Link } from 'react-router-dom';
import { getUserData, setLocalData } from '../../services/storage';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
const loggedInUser = getUserData();

const Checkout = (props) => {
  const { cart, address, selectedOrderType, selectedDate, selectedTime } = props;
  // console.log('-----cart', cart);
  // console.log('-----address', address);
  // console.log('-----selectedOrderType', selectedOrderType);
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [succeeded, setSucceeded] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setProcessing(true);

    const cardElement = elements.getElement('card');

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setPaymentError(error.message);
      setProcessing(false);
      return;
    }

    // Make a request to your server to complete the payment
    // with the payment method ID and any other required data.
    //set essential cart details//

    console.log('cart', cart);
    const currentCart = cart.cart;

    await placeOrder(currentCart, paymentMethod);
    //send to server for payment
  };

  const saveCartAddress = async (e) => {
    await fetch(API_GET_CART + '?id=default&related=address', {
      method: 'POST',
      crossDomain: true,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: [
          {
            id: 'payment',
            attributes: {
              'order.base.address.addressid': address.id,
              'order.base.address.firstname': address.attributes['customer.address.firstname'],
              'order.base.address.lastname': address.attributes['customer.address.lastname'],
              'order.base.address.address1': address.attributes['customer.address.address1'],
              'order.base.address.address2': address.attributes['customer.address.address2'],
              'order.base.address.address3': '',
              'order.base.address.postal': address.attributes['customer.address.postal'],
              'order.base.address.city': address.attributes['customer.address.city'],
              'order.base.address.state': address.attributes['customer.address.state'],
              'order.base.address.countryid': 'US',
              'order.base.address.languageid': 'en',
              'order.base.address.telephone': address.attributes['customer.address.telephone'],
              'order.base.address.email': 'test@example.com',
            },
          },
        ],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('save address', data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const placeOrder = async (cart, paymentMethod) => {
    //save cart
    console.log('paymentMethod', paymentMethod);
    await fetch(API_GET_CART, {
      method: 'POST',
      crossDomain: true,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('save basket', data);
        if (data.errors) {
          data.errors.forEach((element) => {
            ShowToast('error', element.title);
          });
          setProcessing(false);
        } else {
          //send checkout API

          fetch(API_CART_CHECKOUT, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              paymentMethodId: paymentMethod.id,
              payment_method: paymentMethod.id,
              amount: (
                parseFloat(cart.data.attributes['order.price']) + parseFloat(cart.data.attributes['order.taxvalue'])
              ).toFixed(2),
              order_id: data.data.id,
            }),
          })
            .then((response) => response.json())
            .then((data2) => {
              // console.log('response', data2.success);
              if (!data2.success) {
                setPaymentError(`Error processing payment. ${data2.error}`);
                setProcessing(false);
                return false;
              }
              // updateOrder(data.data);

              ShowToast('success', 'Order Placed');
              handleClearCart();
              window.location.href = '/shop/checkout/success/' + encryptString(data.data.id);
            })
            .catch((error) => {
              console.log(error);
              setProcessing(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setProcessing(false);
      });
  };

  const handleClearCart = (e) => {
    // setIsCartClearLoading(true);
    fetch(API_GET_CART + '?id=default', {
      method: 'DELETE',
      crossDomain: true,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('cart deleted', data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ marginBottom: '1rem' }}>
        <label htmlFor="cardNumber" style={{ fontSize: '20px', marginBottom: '0.5rem', display: 'block' }}>
          Card Number
        </label>
        <div style={{ border: '1px solid #d8d8d8', borderRadius: '5px', padding: '10px' }}>
          <CardElement
            id="card"
            options={{
              style: {
                base: {
                  fontSize: '18px',
                  color: '#424770',
                  letterSpacing: '0.025em',
                  fontFamily: 'Source Code Pro, monospace',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
        </div>
      </div>

      {paymentError && <div style={{ color: '#9e2146', fontSize: '16px', marginTop: '1rem' }}>{paymentError}</div>}
      {succeeded && <div style={{ color: '#008000', fontSize: '16px', marginTop: '1rem' }}>Payment succeeded!</div>}

      <button type="submit" className="btn btn-primary btn-xl btn-block" disabled={!stripe}>
        {processing ? 'Processing...' : 'Place Order'}
      </button>
    </form>
  );
};

function CartCheckout(props) {
  const { productSlug, layout } = props;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaceOrderButtonLoading, setIsPlaceOrderButtonLoading] = useState(false);
  const [pinCodeError, setPinCodeError] = useState(false);
  const [deliveryPriceIsLoading, setDeliveryPriceIsLoading] = useState(false);
  const [cart, setCart] = useState({ products: [], details: { attributes: [] }, cart: { included: [] } });
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(false);
  const [selectedOrderType, setSelectedOrderType] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [showTimeMessage, setShowTimeMessage] = useState(false);
  const [showBillingAddressForm, setShowBillingAddressForm] = useState(true);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [billingAddressDetails, setBillingAddressDetails] = useState({
    firstname: '',
    lastname: '',
    country: '',
    address: '',
    address1: '',
    city: '',
    state: '',
    email: '',
    phone: '',
    postcode: '',
    companyname: '',
  });
  const [location, setLocation] = useState();
  const [orderScheduledDateTime, setOrderScheduledDateTime] = useState(new Date());

  // let handleColor = (time) => {
  //   return time.getHours() > 12 ? "text-success" : "text-error";
  // };

  const handleBillingAddressChange = () => {
    setShowBillingAddressForm(!showBillingAddressForm);
  };

  const handleDateTimeChange = (date) => {
    // Check if the selected date and time are in the past
    if (isPast(date)) {
      ShowToast('error', 'Please select a future date and time.');
      setOrderScheduledDateTime(null);
      return;
    }

    const currentTime = new Date();
    const minimumTime = new Date(currentTime.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours to current time

    // Check if the selected date and time doesn't have a buffer of 2 hours from the current time
    if (date.getTime() < minimumTime.getTime()) {
      ShowToast('error', 'Please select a date and time with a buffer of at least 2 hours from the current time.');
      setOrderScheduledDateTime(null);
      return;
    }

    setOrderScheduledDateTime(date);
  };

  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const currentMinute = currentDate.getMinutes();

  const minimumTime =
    currentHour < 7 || (currentHour === 7 && currentMinute === 0)
      ? currentDate
      : setMinutes(setHours(addDays(currentDate, 1), 7), 0);

  useEffect(() => {
    const startTime = setHours(setMinutes(currentDate, 0), 7); // 7 AM
    const endTime = setHours(setMinutes(currentDate, 0), 17); // 5 PM
    const timeSlotMinutes = 15;
    let defaultTime = addHours(currentDate, 2); //default buffer for 2 hrs
    const differenceInMins = differenceInMinutes(defaultTime, startTime);
    const timeSlotCount = Math.ceil(differenceInMins / timeSlotMinutes);
    defaultTime = addMinutes(startTime, timeSlotCount * timeSlotMinutes);

    if (defaultTime < startTime || defaultTime > endTime) {
      defaultTime = endTime;
    }

    setOrderScheduledDateTime(defaultTime);
  }, []);

  const handleDateChange = (event) => {
    const date = event.target.value;
    setSelectedDate(date);
  };

  // Handle time change
  const handleTimeChange = (event) => {
    const time = event.target.value;
    const selectedHour = parseInt(time.split(':')[0]);

    const parts = time.split(':');
    const hours = parseInt(parts[0]);
    const minutes = parseInt(parts[1]);

    if (hours < 6 || hours > 17 || (hours === 17 && minutes > 0)) {
      ShowToast('error', 'Please select a time between 6 AM and 5 PM.');
      return false;
    } else {
      event.target.setCustomValidity('');
    }

    setSelectedTime(time);

    if (selectedHour >= 17 || selectedHour < 6) {
      console.log('Selected time is between 5 PM and 6 AM');
      setShowTimeMessage(true);
    } else {
      setShowTimeMessage(false);
    }
  };

  useEffect(() => {
    fetch(API_GET_ADDRESS, {
      method: 'GET',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log('address list', data.data);
        if (data.data.length > 0) {
          setSelectedAddress(data.data[0]);
        }
        setAddressList(data.data);
      });
  }, []);

  useEffect(() => {
    if (!loggedInUser.isLoggedIn) {
      setLocalData('redirect', '/shop/checkout');
      window.location = '/account/login';
    }
  });

  // Load Cart.
  useEffect(() => {
    setIsLoading(true);
    fetch(API_GET_CART + '?id=default', {
      method: 'GET',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('cart', data);
        const cartProducts = data.included.filter((product) => {
          // console.log(product);
          return product.type === 'basket.product';
        });

        // console.log('cartProducts', cartProducts);
        // setCart(data);
        setCart((prevState) => ({
          ...prevState,
          products: cartProducts,
          details: data.data,
          cart: data,
        }));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChangeOrderType = (type) => {
    setSelectedOrderType(type);
    if (type === 'pickup') {
      fetch(API_GET_CART + '?id=default&related=service&relatedid=delivery', {
        method: 'DELETE',
        crossDomain: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log('save delever ', data);
          const cartProducts = data.included.filter((product) => {
            console.log(product);
            return product.type === 'basket.product';
          });

          console.log('cartProducts', cartProducts);
          // setCart(data);
          setCart((prevState) => ({
            ...prevState,
            products: cartProducts,
            details: data.data,
            cart: data,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (type === 'delivery') {
      fetch(API_GET_CART + '?id=default&related=service&relatedid=delivery', {
        method: 'POST',
        crossDomain: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: [
            {
              id: 'delivery',
              attributes: {
                'service.id': process.env.REACT_APP_DELIVERY_SERVICE,
              },
            },
          ],
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log('save delever ', data);
          const cartProducts = data.included.filter((product) => {
            console.log(product);
            return product.type === 'basket.product';
          });

          console.log('cartProducts', cartProducts);
          // setCart(data);
          setCart((prevState) => ({
            ...prevState,
            products: cartProducts,
            details: data.data,
            cart: data,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const clearAddressService = async () => {
    await fetch(API_GET_CART + '?id=default&related=address&relatedid=delivery', {
      method: 'DELETE',
      crossDomain: true,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => { })
      .catch((error) => {
        console.log(error);
        setIsPlaceOrderButtonLoading(false);
      });
    await fetch(API_GET_CART + '?id=default&related=address&relatedid=payment', {
      method: 'DELETE',
      crossDomain: true,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => { })
      .catch((error) => {
        console.log(error);
        setIsPlaceOrderButtonLoading(false);
      });
  };

  const addAddressToCart = async (e) => {
    console.log('address', selectedAddress);
    console.log('address', showBillingAddressForm);

    await clearAddressService();

    let data = [];

    if (selectedOrderType === 'pickup') {
      data.push({
        id: 'payment',
        attributes: {
          // 'order.base.address.addressid': selectedAddress.id,
          'order.base.address.firstname': 'Pickup',
          'order.base.address.lastname': 'Order',
          'order.base.address.address1': 'Store Pickup',
          'order.base.address.address2': '3173 Muskoka District Road',
          'order.base.address.address3': '',
          'order.base.address.postal': ' P0C 1A0',
          'order.base.address.city': 'Bala',
          'order.base.address.state': 'ON',
          'order.base.address.countryid': 'US',
          'order.base.address.languageid': 'en',
          'order.base.address.telephone': '',
          'order.base.address.email': 'info@cottagebutcher.com',
        },
      });
    }

    if (selectedOrderType === 'delivery') {
      data.push({
        id: 'delivery',
        attributes: {
          // 'order.base.address.addressid': selectedAddress.id,
          'order.base.address.firstname': selectedAddress.attributes['customer.address.firstname'],
          'order.base.address.lastname': selectedAddress.attributes['customer.address.lastname'],
          'order.base.address.address1': selectedAddress.attributes['customer.address.address1'],
          'order.base.address.address2': selectedAddress.attributes['customer.address.address2'],
          'order.base.address.address3': '',
          'order.base.address.postal': selectedAddress.attributes['customer.address.postal'],
          'order.base.address.city': selectedAddress.attributes['customer.address.city'],
          'order.base.address.state': selectedAddress.attributes['customer.address.state'],
          'order.base.address.countryid': 'US',
          'order.base.address.languageid': 'en',
          'order.base.address.telephone': selectedAddress.attributes['customer.address.telephone'],
          'order.base.address.email': selectedAddress.attributes['customer.address.email'],
        },
      });

      if (showBillingAddressForm) {
        data.push({
          id: 'payment',
          attributes: {
            // 'order.base.address.addressid': selectedAddress.id,
            'order.base.address.firstname': selectedAddress.attributes['customer.address.firstname'],
            'order.base.address.lastname': selectedAddress.attributes['customer.address.lastname'],
            'order.base.address.address1': selectedAddress.attributes['customer.address.address1'],
            'order.base.address.address2': selectedAddress.attributes['customer.address.address2'],
            'order.base.address.address3': '',
            'order.base.address.postal': selectedAddress.attributes['customer.address.postal'],
            'order.base.address.city': selectedAddress.attributes['customer.address.city'],
            'order.base.address.state': selectedAddress.attributes['customer.address.state'],
            'order.base.address.countryid': 'US',
            'order.base.address.languageid': 'en',
            'order.base.address.telephone': selectedAddress.attributes['customer.address.telephone'],
            'order.base.address.email': selectedAddress.attributes['customer.address.email'],
          },
        });
      } else {
        data.push({
          id: 'payment',
          attributes: {
            // 'order.base.address.addressid': selectedAddress.id,
            'order.base.address.firstname': billingAddressDetails.firstname,
            'order.base.address.lastname': billingAddressDetails.lastname,
            'order.base.address.address1': billingAddressDetails.address,
            'order.base.address.address2': billingAddressDetails.address1,
            'order.base.address.address3': '',
            'order.base.address.postal': billingAddressDetails.postcode,
            'order.base.address.city': billingAddressDetails.city,
            'order.base.address.state': billingAddressDetails.state,
            'order.base.address.countryid': 'US',
            'order.base.address.languageid': 'en',
            'order.base.address.telephone': billingAddressDetails.mobile,
            'order.base.address.email': billingAddressDetails.email,
          },
        });
      }
    }

    await fetch(API_GET_CART + '?id=default&related=address', {
      method: 'POST',
      crossDomain: true,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('save address', data);
      })
      .catch((error) => {
        console.log(error);
        setIsPlaceOrderButtonLoading(false);
      });
  };

  const saveCartPaymentService = async (e) => {
    await fetch(API_GET_CART + '?id=default&related=service&relatedid=payment', {
      method: 'POST',
      crossDomain: true,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: [
          {
            id: 'payment',
            attributes: {
              'service.id': process.env.REACT_APP_PAYMENT_STRIPE,
            },
          },
        ],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('save payment', data);
      })
      .catch((error) => {
        console.log(error);
        setIsPlaceOrderButtonLoading(false);
      });
  };

  const updateOrderServices = async (e) => {
    await fetch(API_GET_CART + '?id=default', {
      method: 'PATCH',
      crossDomain: true,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          attributes: {
            order_type: getOrderTypeValue(selectedOrderType),
            order_place_type: 'WEBSITE',
            // is_schedule_delivery: selectedOrderType === 'pickup' ? 1 : 0,
            is_schedule_delivery: 1,
            scheduled_time: `${format(orderScheduledDateTime, 'yyyy-MM-dd HH:mm:ss')}`,
            payment_method: 'STRIPE',
          },
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('cart updated', data);
      })
      .catch((error) => {
        console.log(error);
        setIsPlaceOrderButtonLoading(false);
      });
  };

  const handleProccedToPayment = async () => {
    if (selectedOrderType === '') {
      ShowToast('error', 'Please select order type');
      return false;
    }
    if (selectedOrderType === 'delivery' && !selectedAddress) {
      ShowToast('error', 'Please select shipping address');
      return false;
    }

    // alert(orderScheduledDateTime);
    // if (selectedOrderType === 'pickup') {
    if (orderScheduledDateTime === '' || orderScheduledDateTime == null) {
      ShowToast('error', 'Please select future date and time');
      return false;
    }

    if (selectedOrderType === 'delivery') {
      const isPinCodeAllowed = checkFilterCodes(selectedAddress.attributes['customer.address.postal']);
      console.log(isPinCodeAllowed);

      if (!isPinCodeAllowed) {
        setPinCodeError(true);
        return false;
      }
    }

    // const parts = selectedTime.split(':');
    // const hours = parseInt(parts[0]);
    // const minutes = parseInt(parts[1]);

    // if (hours < 6 || hours > 17 || (hours === 17 && minutes > 0)) {
    //   ShowToast('error', 'Please select a time between 6 AM and 5 PM.');
    //   return false;
    // }
    // }

    setIsPlaceOrderButtonLoading(true);
    await addAddressToCart();
    await saveCartPaymentService();
    await updateOrderServices();
    setShowPaymentForm(true);
    setIsPlaceOrderButtonLoading(false);
  };

  const handleAddAddress = () => {
    setLocalData('cartRedirect', '/shop/checkout');
    history.push('/account/addresses/create');
  };

  const handleBillingInputChange = (event) => {
    const { name, value } = event.target;
    setBillingAddressDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePlaceSelected = (place) => {
    const address_components = place.value;
    console.log(place);
    // console.log(address_components);

    const address = address_components.structured_formatting?.main_text?.split(',')[0];
    const city = address_components.structured_formatting?.secondary_text?.split(',')[0];
    const state = address_components.structured_formatting?.secondary_text?.split(',')[1]?.trim();
    const country = address_components.structured_formatting?.secondary_text?.split(',')[2]?.trim();

    setLocation(place);
    setBillingAddressDetails((prevState) => ({
      ...prevState,
      address,
      city,
      state,
      country,
    }));
  };

  if (isLoading) {
    return <BlockLoader />;
  }

  return (
    <React.Fragment>
      <Helmet>{/* <title>{`${product.name} — ${theme.name}`}</title> */}</Helmet>

      {/* <PageHeader breadcrumb={breadcrumb} /> */}
      <div className="site__body">
        <div className="page-header">
          <div className="page-header__container container">
            <div className="page-header__breadcrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                    <svg xmlns="http://www.w3.org/2000/svg" width={6} height={9} className="breadcrumb-arrow">
                      <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                    </svg>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/shop/cart">Shopping Cart</a>
                    <svg xmlns="http://www.w3.org/2000/svg" width={6} height={9} className="breadcrumb-arrow">
                      <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                    </svg>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Checkout
                  </li>
                </ol>
              </nav>
            </div>
            <div className="page-header__title">
              <h1>Checkout</h1>
            </div>
          </div>
        </div>
        <div className="checkout block">
          <div className="container">
            <div className="row">
              {loggedInUser.isLoggedIn ? null : (
                <div className="col-12 mb-3">
                  <div className="alert alert-primary alert-lg">
                    Returning customer? <a href="/account/login">Click here to login</a>
                  </div>
                </div>
              )}

              {cart.products.length > 0 ? (
                <>
                  {' '}
                  <div className={`col-12 col-lg-6 col-xl-7 ${showPaymentForm ? 'order-blur' : ''}`}>
                    <h3 className="card-title">Order Type</h3>
                    <div className="order_type">
                      <ul>
                        <li
                          className={selectedOrderType === 'pickup' ? 'selected' : ''}
                          onClick={() => handleChangeOrderType('pickup')}
                        >
                          <i>
                            <img src="images/pickup.svg" alt="pickup" />
                          </i>
                          PickUp
                        </li>
                        <li
                          className={selectedOrderType === 'delivery' ? 'selected' : ''}
                          onClick={() => handleChangeOrderType('delivery')}
                        >
                          <i>
                            <img src="images/delivery.svg" alt="delivery" />
                          </i>
                          Delivery
                        </li>
                        <li className="disable">
                          Shipping
                          <small>coming soon</small>
                        </li>
                      </ul>
                    </div>
                    {selectedOrderType && (
                      <div className="select_slot mb-3">
                        <h3 className="card-title mb-3">Select Slot</h3>
                        <div className="row">
                          <div className="col-md-12">
                            <DatePicker
                              showTimeSelect
                              selected={orderScheduledDateTime}
                              onChange={(date) => handleDateTimeChange(date)}
                              minDate={new Date()}
                              minTime={minimumTime}
                              maxTime={setHours(setMinutes(new Date(), 0), 17)}
                              dateFormat="yyyy/MM/dd HH:mm"
                              timeIntervals={15} // Set the time intervals to 15 minutes
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-12 mt-3">
                            {selectedOrderType === 'delivery' && (
                              <div className="alert alert-info alert-sm">
                                Orders placed 2 hours before closing will be delivered the next day. If you need
                                delivery before then, please call us to check delivery availability prior to order.
                              </div>
                            )}
                            {selectedOrderType === 'pickup' && (
                              <div className="alert alert-info alert-sm">
                                Please allow at least two hours for your pick-up order to be ready in store. This gives
                                us time to ensure the quality and completeness of each customer’s order and serve those
                                who are already in-store with care and courtesy.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {selectedOrderType === 'delivery' ? (
                      <>
                        {' '}
                        <h3 className="card-title">Shipping Address</h3>
                        <div className="addresses-list">
                          <a onClick={handleAddAddress} className="addresses-list__item addresses-list__item--new">
                            <div className="addresses-list__plus" />
                            <div className="btn btn-secondary btn-sm">Add New</div>
                          </a>
                          <div className="addresses-list__divider" />
                          {/* {addresses} */}
                          {addressList.map((address, key) => (
                            <>
                              <div
                                key={key}
                                className="addresses-list__item card address-card cursor"
                                onClick={(e) => setSelectedAddress(address, e)}
                              >
                                {selectedAddress.id === address.id && (
                                  <div className="address-card__badge">Selected</div>
                                )}
                                <div className="address-card__body">
                                  <div className="address-card__name">{`${address.attributes['customer.address.firstname']} ${address.attributes['customer.address.lastname']}`}</div>
                                  <div className="address-card__row">
                                    {address.attributes['customer.address.address1']}
                                    <br />
                                    {address.attributes['customer.address.city']},{' '}
                                    {address.attributes['customer.address.state']},{' '}
                                    {address.attributes['customer.address.postal']}
                                    <br />
                                    {address.attributes['customer.address.countryid']}
                                  </div>
                                  <div className="address-card__row">
                                    <div className="address-card__row-title">Mobile</div>
                                    <div className="address-card__row-content">
                                      {' '}
                                      {address.attributes['customer.address.telephone']}
                                    </div>
                                  </div>
                                  <div className="address-card__footer">
                                    {/* <Link to="/account/addresses/5">Edit</Link> */}
                                  </div>
                                </div>
                              </div>
                              <div className="addresses-list__divider" />
                            </>
                          ))}
                        </div>
                        <h3 className="card-title mt-4">Billing Address</h3>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              checked={showBillingAddressForm}
                              onChange={handleBillingAddressChange}
                            />{' '}
                            Same as shipping address.
                          </label>
                          {!showBillingAddressForm && (
                            <div className="row no-gutters">
                              <div className="col-12 col-lg-10 col-xl-8">
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label htmlFor="checkout-first-name">First Name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="checkout-first-name"
                                      placeholder="First Name"
                                      name="firstname"
                                      value={billingAddressDetails.firstname}
                                      onChange={handleBillingInputChange}
                                      onBlur={() => simpleValidator.current.showMessageFor('firstname')}
                                    />
                                    {simpleValidator.current.message(
                                      'firstname',
                                      billingAddressDetails.firstname,
                                      'required'
                                    )}
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label htmlFor="checkout-last-name">Last Name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="checkout-last-name"
                                      placeholder="Last Name"
                                      name="lastname"
                                      value={billingAddressDetails.lastname}
                                      onChange={handleBillingInputChange}
                                      onBlur={() => simpleValidator.current.showMessageFor('lastname')}
                                    />
                                    {simpleValidator.current.message(
                                      'lastname',
                                      billingAddressDetails.lastname,
                                      'required'
                                    )}
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="checkout-company-name">
                                    Company Name <span className="text-muted">(Optional)</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-company-name"
                                    placeholder="Company Name"
                                    name="companyname"
                                    value={billingAddressDetails.companyname}
                                    onChange={handleBillingInputChange}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="checkout-street-address">Address</label>
                                  {/* <input type="text" className="form-control" id="checkout-street-address" placeholder="Street Address" /> */}
                                  <GooglePlacesAutocomplete
                                    apiKey={process.env.REACT_APP_GOOGLE_API}
                                    autocompletionRequest={{
                                      componentRestrictions: {
                                        country: 'ca',
                                      },
                                    }}
                                    selectProps={{
                                      value: location,
                                      onChange: handlePlaceSelected,
                                      placeholder: 'Enter address',
                                    }}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="checkout-city">Address Line 1</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-city"
                                    name="address"
                                    value={billingAddressDetails.address}
                                    onChange={handleBillingInputChange}
                                    onBlur={() => simpleValidator.current.showMessageFor('address')}
                                  />
                                  {simpleValidator.current.message(
                                    'address',
                                    billingAddressDetails.address,
                                    'required'
                                  )}
                                </div>

                                <div className="form-group">
                                  <label htmlFor="checkout-city">Address Line 2</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-city"
                                    name="address2"
                                    value={billingAddressDetails.address1}
                                    onChange={handleBillingInputChange}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="checkout-city">Town / City</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-city"
                                    value={billingAddressDetails.city}
                                    name="city"
                                    onChange={handleBillingInputChange}
                                    onBlur={() => simpleValidator.current.showMessageFor('city')}
                                  />
                                  {simpleValidator.current.message('city', billingAddressDetails.city, 'required')}
                                </div>
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label htmlFor="checkout-state">State </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="checkout-state"
                                      value={billingAddressDetails.state}
                                      name="state"
                                      onChange={handleBillingInputChange}
                                      onBlur={() => simpleValidator.current.showMessageFor('state')}
                                    />
                                    {simpleValidator.current.message('state', billingAddressDetails.state, 'required')}
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label htmlFor="checkout-postcode">Country</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="checkout-postcode"
                                      value={billingAddressDetails.country}
                                      name="country"
                                      onChange={handleBillingInputChange}
                                      onBlur={() => simpleValidator.current.showMessageFor('country')}
                                    />
                                    {simpleValidator.current.message(
                                      'country',
                                      billingAddressDetails.country,
                                      'required'
                                    )}
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="checkout-postcode">Postcode / ZIP</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-postcode"
                                    name="postcode"
                                    value={billingAddressDetails.postcode}
                                    onChange={handleBillingInputChange}
                                    onBlur={() => simpleValidator.current.showMessageFor('postcode')}
                                  />
                                  {simpleValidator.current.message(
                                    'postcode',
                                    billingAddressDetails.postcode,
                                    'required|max:7'
                                  )}
                                </div>
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label htmlFor="checkout-email">Email address</label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      id="checkout-email"
                                      placeholder="Email address"
                                      name="email"
                                      value={billingAddressDetails.email}
                                      onChange={handleBillingInputChange}
                                    />
                                    {simpleValidator.current.message('email', billingAddressDetails.email, 'required')}
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label htmlFor="checkout-phone">Mobile</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="checkout-phone"
                                      placeholder="Mobile"
                                      name="mobile"
                                      value={billingAddressDetails.mobile}
                                      onChange={handleBillingInputChange}
                                      onBlur={() => simpleValidator.current.showMessageFor('mobile')}
                                    />
                                    {simpleValidator.current.message(
                                      'mobile',
                                      billingAddressDetails.mobile,
                                      'required|max:11'
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {selectedOrderType === 'pickup' ? (
                      <>
                        {' '}
                        {showTimeMessage && (
                          <div className="alert alert-danger alert-lg">
                            Orders placed 2 hours before closing will be delivered the next day. If you need delivery
                            before then, please call us to check delivery availability prior to order.
                          </div>
                        )}
                        <h3 className="card-title">Store Address</h3>
                        <p>3173 Muskoka District Road 169, Bala,ON P0C 1A0, Canada</p>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                    <div className="card mb-0">
                      <div className="card-body">
                        <h3 className="card-title">Your Order</h3>
                        <table className="checkout__totals">
                          <thead className="checkout__totals-header">
                            <tr>
                              <th>Product</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody className="checkout__totals-products">
                            {cart.products.map((item, key) => (
                              <tr key={key}>
                                <td>
                                  {' '}
                                  {item.attributes['order.product.name']}{' '}
                                  <small>
                                    @$
                                    {item.attributes['order.product.price']}
                                  </small>{' '}
                                  × {item.attributes['order.product.quantity']}
                                </td>
                                <td>
                                  $
                                  {parseAmount(
                                    item.attributes['order.product.price'] * item.attributes['order.product.quantity']
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tbody className="checkout__totals-subtotals">
                            <tr>
                              <th>Subtotal</th>
                              <td>${parseAmount(cart.details.attributes['order.price'])}</td>
                            </tr>
                            <tr>
                              <th>Tax</th>
                              <td>${parseAmount(cart.details.attributes['order.taxvalue'])}</td>
                            </tr>
                            {selectedOrderType === 'delivery' || !selectedOrderType ? (
                              <tr>
                                <th>Delivery</th>
                                <td>${parseAmount(cart.details.attributes['order.costs'])}</td>
                              </tr>
                            ) : null}
                            {/* {cart.details.attributes['order.rebate'] > 0 ? (
                              <tr>
                                <th>Rebate</th>
                                <td>- ${parseAmount(cart.details.attributes['order.rebate'])}</td>
                              </tr>
                            ) : null} */}
                          </tbody>
                          <tfoot className="checkout__totals-footer">
                            <tr>
                              <th>Total</th>
                              <td>${parseAmount(cart.details.attributes['order.final_amount'])}</td>
                            </tr>
                          </tfoot>
                        </table>

                        {!showPaymentForm && (
                          <button
                            onClick={handleProccedToPayment}
                            type="submit"
                            className="btn btn-primary btn-xl btn-block"
                          >
                            Procced to Payment{' '}
                            {isPlaceOrderButtonLoading ? <i className="fa fa-spin fa-spinner"></i> : null}
                          </button>
                        )}

                        {showPaymentForm && (
                          <>
                            <Elements stripe={stripePromise}>
                              <Checkout
                                cart={cart}
                                address={selectedAddress}
                                selectedOrderType={selectedOrderType}
                                selectedDate={selectedDate}
                                selectedTime={selectedTime}
                              />
                            </Elements>
                            <a
                              className="text-center mt-2 d-block"
                              href="javascript:;"
                              onClick={() => setShowPaymentForm(false)}
                            >
                              Back
                            </a>
                          </>
                        )}

                        {/* {selectedOrderType}
                        <br></br>
                        {selectedAddress} */}
                        {/* {selectedOrderType === 'delivery' ? (
                          selectedAddress ? (
                            <div className="payment-methods">
                              <Elements stripe={stripePromise}>
                                <Checkout
                                  cart={cart}
                                  address={selectedAddress}
                                  selectedOrderType={selectedOrderType}
                                  selectedDate={selectedDate}
                                  selectedTime={selectedTime}
                                />
                              </Elements>
                            </div>
                          ) : (
                            <div style={{ color: '#9e2146', fontSize: '16px', marginTop: '1rem' }}>
                              Please select address first
                            </div>
                          )
                        ) : null}
                        {selectedOrderType === 'pickup' ? (
                          <Elements stripe={stripePromise}>
                            <Checkout
                              cart={cart}
                              address={selectedAddress}
                              selectedOrderType={selectedOrderType}
                              selectedTime={selectedTime}
                              selectedDate={selectedDate}
                            />
                          </Elements>
                        ) : null}
                        {selectedOrderType === '' ? (
                          <div style={{ color: '#9e2146', fontSize: '16px', marginTop: '1rem' }}>
                            Please select delivery type
                          </div>
                        ) : null} */}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {' '}
                  <div className="col-12 mb-3">
                    <div className="alert alert-primary alert-lg">
                      Your shopping cart is empty! <Link to={'/'}>Click here to shop.</Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div class={`custom-modal ${pinCodeError ? 'show' : ''}`} id="myModal">
        <div class="custom-modal-content">
          <span class="custom-modal-close" onClick={() => setPinCodeError(false)}>
            &times;
          </span>
          <h3>Oh no!</h3>
          <div class="alert alert-danger alert-sm">
            We don’t deliver to your area just yet. We set our delivery range to ensure a speedy delivery and that your
            food arrives fresh. Please select another order option or contact us at{' '}
            <a href="mailto:info@cottagebutcher.com">info@cottagebutcher.com</a> or call us
            <a href="tel:7057625995">705-762-5995.</a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

CartCheckout.propTypes = {
  productSlug: PropTypes.string,
  layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
  sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

CartCheckout.defaultProps = {
  layout: 'standard',
  sidebarPosition: 'start',
};

export default CartCheckout;
