// react
import React, { useState, useRef } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import SimpleReactValidator from 'simple-react-validator';
import { ShowToast } from '../../services/helpers';
// data stubs
import theme from '../../data/theme';
import { getUserData, setLocalDataObject, setUserData } from '../../services/storage';
import { API_PROFILE_UPDATE } from '../../config/endpoints';
const loggedInUser = getUserData();

export default function AccountPageProfile() {
  const [isBtnLoading, setIsBtnLoading] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [email, setEmail] = useState(loggedInUser.user.email);
  const [firstName, setFirstName] = useState(loggedInUser.user.firstname);
  const [lastName, setLastName] = useState(loggedInUser.user.lastname);

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsBtnLoading(true);
      await fetch(API_PROFILE_UPDATE, {
        method: 'POST',
        crossDomain: true,
        credentials: 'include',
        body: JSON.stringify({
          email: email,
          firstname: firstName,
          lastname: lastName,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          console.log(data.success);
          if (!data.success) {
            const errorList = data.error;
            Object.keys(errorList).forEach(function (key) {
              ShowToast('error', errorList[key][0]);
            });
          } else {
            ShowToast('success', 'Profile Updated.');
            setLocalDataObject('cbUser', data.user);
            // window.location = '';
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setIsBtnLoading(false);
    }
  };

  return (
    <div className="card">
      <Helmet>
        <title>{`Profile — ${theme.name}`}</title>
      </Helmet>

      <div className="card-header">
        <h5>Edit Profile</h5>
      </div>
      <div className="card-divider" />
      <div className="card-body">
        <div className="row no-gutters">
          <div className="col-12 col-lg-7 col-xl-6">
            <div className="form-group">
              <label htmlFor="profile-first-name">First Name</label>
              <input
                id="profile-first-name"
                type="text"
                defaultValue={loggedInUser.user.firstname}
                className="form-control"
                placeholder="First Name"
                name="firstname"
                onChange={(e) => setFirstName(e.target.value)}
                onBlur={() => simpleValidator.current.showMessageFor('firstName')}
              />
              {simpleValidator.current.message('firstName', firstName, 'required|min:4')}
            </div>
            <div className="form-group">
              <label htmlFor="profile-last-name">Last Name</label>
              <input
                id="profile-last-name"
                type="text"
                defaultValue={loggedInUser.user.lastname}
                className="form-control"
                placeholder="Last Name"
                name="lastname"
                onChange={(e) => setLastName(e.target.value)}
                onBlur={() => simpleValidator.current.showMessageFor('lastName')}
              />
              {simpleValidator.current.message('lastName', lastName, 'required|min:4')}
            </div>
            <div className="form-group">
              <label htmlFor="profile-email">Email Address</label>
              <input
                id="profile-email"
                type="email"
                defaultValue={loggedInUser.user.email}
                className="form-control"
                placeholder="Email Address"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => simpleValidator.current.showMessageFor('email')}
              />
              {simpleValidator.current.message('email', email, 'required|email')}
            </div>
            <div className="form-group">
              <label htmlFor="profile-number">Mobile Number</label>
              <input type="phone" className="form-control" placeholder="Mobile Number" />
            </div>
            <div className="form-group mt-5 mb-0">
              <button type="button" disabled={isBtnLoading} onClick={handleProfileUpdate} className="btn btn-primary">
                Save
                <i className={isBtnLoading ? `fa fa-spin fa-spinner` : ``} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
