// react
import React, { useEffect, useState } from 'react';

// third-party
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { API_GET_PRODUCTS } from '../../config/endpoints';
import { getProductUrl } from '../../services/helpers';

function MenuProducts(props) {
  const { categoryDetails } = props;
  const [productList, setProductList] = useState([]);
  useEffect(() => {
    fetch(API_GET_PRODUCTS + '?filter[f_catid]=' + categoryDetails.id, {
      method: 'GET',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        const inStockProducts = data.data.filter((product) => {
          // console.log(product.attributes['product.instock']);
          return product.attributes['product.instock'] === 1;
        });
        // console.log(inStockProducts);
        setProductList(inStockProducts);
        // setProductList(data.data);
        // console.log(data.included);
        // setCategoriesList(data.included);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [categoryDetails]);

  return (
    <ul className="megamenu__links megamenu__links--level--1">
      {productList.map((item, key) => (
        <li key={key} className="megamenu__item">
          <a href={getProductUrl(item)}>{item.attributes['product.label']}</a>
        </li>
      ))}
    </ul>
  );
}

MenuProducts.propTypes = {
  categoryDetails: PropTypes.object,
};

MenuProducts.defaultProps = {
  categoryDetails: {},
};

export default MenuProducts;
