// react
import React, { useEffect, useRef, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import SimpleReactValidator from 'simple-react-validator';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useHistory } from 'react-router-dom';
import { ShowToast } from '../../services/helpers';
import { API_GET_ADDRESS } from '../../config/endpoints';

// data stubs
import theme from '../../data/theme';
import { deleteLocalData, getLocalData } from '../../services/storage';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function AccountPageEditAddress() {
  const { addressId } = useParams();
  const history = useHistory();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [location, setLocation] = useState();
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [addressDetails, setAddressDetails] = useState({
    firstname: '',
    lastname: '',
    country: '',
    address: '',
    address1: '',
    city: '',
    state: '',
    email: '',
    phone: '',
    postcode: '',
    companyname: '',
  });

  const handlePlaceSelected = (place) => {
    const address_components = place.value;
    console.log(place);
    // console.log(address_components);

    const address = address_components.structured_formatting?.main_text?.split(',')[0];
    const city = address_components.structured_formatting?.secondary_text?.split(',')[0];
    const state = address_components.structured_formatting?.secondary_text?.split(',')[1]?.trim();
    const country = address_components.structured_formatting?.secondary_text?.split(',')[2]?.trim();

    setLocation(place);
    setAddressDetails((prevState) => ({
      ...prevState,
      address,
      city,
      state,
      country,
    }));
  };

  const handleAddressCreate = async () => {
    console.log(addressDetails);
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsBtnLoading(true);
      await fetch(API_GET_ADDRESS, {
        method: 'POST',
        crossDomain: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: [
            {
              attributes: {
                'customer.address.salutation': 'mr',
                'customer.address.company': addressDetails.companyname,
                'customer.address.firstname': addressDetails.firstname,
                'customer.address.lastname': addressDetails.lastname,
                'customer.address.address1': addressDetails.address,
                'customer.address.address2': addressDetails.address1,
                'customer.address.postal': addressDetails.postcode,
                'customer.address.city': addressDetails.city,
                'customer.address.state': addressDetails.state,
                // 'customer.address.countryid': addressDetails.country,
                // 'customer.address.countryid': addressDetails.country,
                // 'customer.address.languageid': 'de',
                'customer.address.telephone': addressDetails.phone,
                // 'customer.address.telefax': '+49123456789',
                'customer.address.email': addressDetails.email,
                // 'customer.address.website': 'https://example.com',
                // 'customer.address.longitude': 10.0,
                // 'customer.address.latitude': 50.0,
              },
            },
          ],
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          console.log(data.success);
          if (!data) {
          } else {
            ShowToast('success', 'Address saved.');
            setIsBtnLoading(false);
            const redirectUrl = getLocalData('cartRedirect');
            if (redirectUrl) {
              deleteLocalData('cartRedirect');
              history.push(redirectUrl);
            } else {
              history.push('/account/addresses');
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getAddressDetails();
  }, []);

  const getAddressDetails = () => {
    fetch(API_GET_ADDRESS + '&relatedid=' + addressId, {
      method: 'GET',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // console.log(data.data.attributes['customer.address.firstname']);

        // ShowToast('success', 'Profile Updated.');
        setAddressDetails({
          firstname: data.data.attributes['customer.address.firstname'],
          lastname: data.data.attributes['customer.address.lastname'],
          country: data.data.attributes['customer.address.countryid'],
          address: data.data.attributes['customer.address.address'],
          address1: data.data.attributes['customer.address.address1'],
          city: data.data.attributes['customer.address.city'],
          state: data.data.attributes['customer.address.state'],
          email: data.data.attributes['customer.address.email'],
          phone: data.data.attributes['customer.address.telephone'],
          postcode: data.data.attributes['customer.address.postal'],
          companyname: data.data.attributes['customer.address.company'],
        });
        // setLocalDataObject('cbUser', data.user);
        // window.location = '';
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAddressDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { firstname, lastname, company, country } = addressDetails;

  return (
    <div className="card">
      <Helmet>
        <title>{`Edit Address — ${theme.name}`}</title>
      </Helmet>

      <div className="card-header">
        <h5>Create Address</h5>
      </div>
      <div className="card-divider" />
      <div className="card-body">
        <div className="row no-gutters">
          <div className="col-12 col-lg-10 col-xl-8">
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="checkout-first-name">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="checkout-first-name"
                  placeholder="First Name"
                  name="firstname"
                  value={addressDetails.firstname}
                  onChange={handleInputChange}
                  onBlur={() => simpleValidator.current.showMessageFor('firstname')}
                />
                {simpleValidator.current.message('firstname', addressDetails.firstname, 'required')}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="checkout-last-name">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="checkout-last-name"
                  placeholder="Last Name"
                  name="lastname"
                  value={addressDetails.lastname}
                  onChange={handleInputChange}
                  onBlur={() => simpleValidator.current.showMessageFor('lastname')}
                />
                {simpleValidator.current.message('lastname', addressDetails.lastname, 'required')}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="checkout-company-name">
                Company Name <span className="text-muted">(Optional)</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="checkout-company-name"
                placeholder="Company Name"
                name="companyname"
                value={addressDetails.companyname}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="checkout-street-address">Address</label>
              {/* <input type="text" className="form-control" id="checkout-street-address" placeholder="Street Address" /> */}
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: 'ca',
                  },
                }}
                selectProps={{
                  value: location,
                  onChange: handlePlaceSelected,
                  placeholder: 'Enter address',
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="checkout-city">Address Line 1</label>
              <input
                type="text"
                className="form-control"
                id="checkout-city"
                name="address"
                value={addressDetails.address}
                onChange={handleInputChange}
                onBlur={() => simpleValidator.current.showMessageFor('address')}
              />
              {simpleValidator.current.message('address', addressDetails.address, 'required')}
            </div>

            <div className="form-group">
              <label htmlFor="checkout-city">Address Line 2</label>
              <input
                type="text"
                className="form-control"
                id="checkout-city"
                name="address2"
                value={addressDetails.address1}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="checkout-city">Town / City</label>
              <input
                type="text"
                className="form-control"
                id="checkout-city"
                value={addressDetails.city}
                name="city"
                onChange={handleInputChange}
                onBlur={() => simpleValidator.current.showMessageFor('city')}
              />
              {simpleValidator.current.message('city', addressDetails.city, 'required')}
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="checkout-state">State </label>
                <input
                  type="text"
                  className="form-control"
                  id="checkout-state"
                  value={addressDetails.state}
                  name="state"
                  onChange={handleInputChange}
                  onBlur={() => simpleValidator.current.showMessageFor('state')}
                />
                {simpleValidator.current.message('state', addressDetails.state, 'required')}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="checkout-postcode">Country</label>
                <input
                  type="text"
                  className="form-control"
                  id="checkout-postcode"
                  value={addressDetails.country}
                  name="country"
                  onChange={handleInputChange}
                  onBlur={() => simpleValidator.current.showMessageFor('country')}
                />
                {simpleValidator.current.message('country', addressDetails.country, 'required')}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="checkout-postcode">Postcode / ZIP</label>
              <input
                type="text"
                className="form-control"
                id="checkout-postcode"
                name="postcode"
                value={addressDetails.postcode}
                onChange={handleInputChange}
                onBlur={() => simpleValidator.current.showMessageFor('postcode')}
              />
              {simpleValidator.current.message('postcode', addressDetails.postcode, 'required')}
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="checkout-email">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="checkout-email"
                  placeholder="Email address"
                  name="email"
                  value={addressDetails.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="checkout-phone">Phone</label>
                <input
                  type="text"
                  className="form-control"
                  id="checkout-phone"
                  placeholder="Phone"
                  name="phone"
                  value={addressDetails.phone}
                  onChange={handleInputChange}
                  onBlur={() => simpleValidator.current.showMessageFor('phone')}
                />
                {simpleValidator.current.message('phone', addressDetails.phone, 'required')}
              </div>
            </div>
            <div className="form-group mt-3 mb-0">
              <button className="btn btn-primary" disabled={isBtnLoading} type="button" onClick={handleAddressCreate}>
                Save <i className={isBtnLoading ? `fa fa-spin fa-spinner` : ``} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
