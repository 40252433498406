// react
import React, { useEffect, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { API_GET_ADDRESS } from '../../config/endpoints';

// data stubs
import dataAddresses from '../../data/accountAddresses';
import theme from '../../data/theme';
import { ShowToast } from '../../services/helpers';

export default function AccountPageAddresses() {
  const [addressList, setAddressList] = useState([]);

  const fetchAddress = () => {
    fetch(API_GET_ADDRESS, {
      method: 'GET',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('address', data.data);
        setAddressList(data.data);
      });
  };

  useEffect(() => {
    fetchAddress();
  }, []);

  const handleAddressRemove = (item) => {
    fetch(API_GET_ADDRESS + '?id=default&related=address&relatedid=' + item, {
      method: 'DELETE',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        ShowToast('success', 'Address deleted.');
        fetchAddress();
      });
  };

  return (
    <div className="addresses-list">
      <Helmet>
        <title>{`Address List — ${theme.name}`}</title>
      </Helmet>

      <Link to="/account/addresses/create" className="addresses-list__item addresses-list__item--new">
        <div className="addresses-list__plus" />
        <div className="btn btn-secondary btn-sm">Add New</div>
      </Link>
      <div className="addresses-list__divider" />
      {/* {addresses} */}
      {addressList.map((address, key) => (
        <>
          <div key={key} className="addresses-list__item card address-card">
            {address.default && <div className="address-card__badge">Default</div>}

            <div className="address-card__body">
              <div className="address-card__name">{`${address.attributes['customer.address.firstname']} ${address.attributes['customer.address.lastname']}`}</div>
              <div className="address-card__row">
                {address.attributes['customer.address.address1']}
                <br />
                {address.attributes['customer.address.city']}, {address.attributes['customer.address.state']},{' '}
                {address.attributes['customer.address.postal']}
                <br />
                {address.attributes['customer.address.countryid']}
              </div>
              <div className="address-card__row">
                <div className="address-card__row-title">Phone Number</div>
                <div className="address-card__row-content"> {address.attributes['customer.address.telephone']}</div>
              </div>
              <div className="address-card__footer">
                <Link to={`/account/addresses/` + address.id}>Edit</Link>
                &nbsp;&nbsp;
                <a href="javascript:;" onClick={() => handleAddressRemove(address.id)}>
                  Remove
                </a>
              </div>
            </div>
          </div>
          <div className="addresses-list__divider" />
        </>
      ))}
    </div>
  );
}
