// react
import React from 'react';
import { ShowToast } from '../../services/helpers';

// application
import { API_GET_CART } from '../../config/endpoints';

function AddToCart(props) {
  const { product, selectedQty, onUpdateCart, groupProduct } = props;
  // console.log('ipdate', groupProduct);

  const handleAddToCart = (e) => {
    let productId = groupProduct ? product.product['product.id'] : product.id;
    e.preventDefault();
    fetch(API_GET_CART + '?id=default&related=product', {
      method: 'POST',
      crossDomain: true,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      // body: '{"data": [{\n    "attributes": {\n        "product.id": "1",\n        "quantity": 1,\n        "stocktype": "default"\n    }\n}]}',
      body: JSON.stringify({
        data: [
          {
            attributes: {
              'product.id': productId,
              quantity: selectedQty,
              stocktype: 'default',
            },
          },
        ],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.errors);
        if (data.errors) {
          data.errors.forEach((element) => {
            // ...use `element`...
            ShowToast('error', element.title);
          });
        } else {
          ShowToast('success', 'Product is added to cart.');
          onUpdateCart(data);
        }

        // setProduct(data);
        // console.log(data.included);
        // setCategoriesList(data.included);
      })
      .catch((error) => {
        console.log(error);
      });

    fetch(API_GET_CART + '?id=default&related=service&relatedid=delivery', {
      method: 'POST',
      crossDomain: true,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: [
          {
            id: 'delivery',
            attributes: {
              'service.id': process.env.REACT_APP_DELIVERY_SERVICE,
            },
          },
        ],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('save delever ', data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <button type="button" className="btn btn-primary btn-lg" onClick={(e) => handleAddToCart(e)}>
      Add to cart
    </button>
  );
}

export default AddToCart;
